import React, { useState } from "react";
import Header from "../../Shared/Header";
import { Typography, Radio, message, Button, Progress, Card } from "antd";
import { motion } from "framer-motion"; // For smooth animations

const { Title } = Typography;

const questions = [
  {
    id: 1,
    question:
      "How often do you experience physical pain or discomfort without a clear cause (e.g., headaches, body pain, muscle aches)?",
    options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
  },
  {
    id: 2,
    question:
      "How often do you feel physically exhausted or drained, even after a normal day of work or activity?",
    options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
  },
  {
    id: 3,
    question:
      "How well do you sleep at night? (i.e., feel rested upon waking up)",
    options: [
      "Very well (restful sleep, wake up refreshed)",
      "Mostly well, but sometimes restless",
      "Average, often wake up tired",
      "Poor, wake up multiple times during the night",
      "Very poor, feel unrested throughout the day",
    ],
  },
  {
    id: 4,
    question:
      "In the past two weeks, how often have you experienced difficulty concentrating, making decisions, or remembering things?",
    options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
  },
  {
    id: 5,
    question: "Do you feel overwhelmed by daily tasks or responsibilities?",
    options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
  },
  {
    id: 6,
    question:
      "Have you noticed any changes in your appetite (e.g., eating more or less than usual)?",
    options: [
      "No change",
      "Slight decrease",
      "Slight increase",
      "Significant decrease",
      "Significant increase",
    ],
  },
  {
    id: 7,
    question:
      "How often do you find yourself feeling anxious, worried, or nervous without a clear reason?",
    options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
  },
  {
    id: 8,
    question:
      "Have you experienced any recent life changes or challenges that might be affecting your health (e.g., work pressure, family issues, loss)?",
    options: [
      "No",
      "Yes, but only minor challenges",
      "Yes, moderate challenges",
      "Yes, major challenges",
      "Yes, overwhelming or traumatic challenges",
    ],
  },
];

function QuizComponent({ setFlagquizsubmit }) {
  const [answers, setAnswers] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (questionId, selectedOption) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: selectedOption,
    }));
  };

  const handleNext = () => {
    if (!(questions[currentIndex].id in answers)) {
      message.error(`Please answer this question before proceeding.`);
      return;
    }

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      const calculatedAnswers = Object.entries(answers).map(
        ([id, selectedOption]) => {
          const question = questions.find((q) => q.id === parseInt(id));
          const selectedOptionIndex = question.options.indexOf(selectedOption);
          return Number(((selectedOptionIndex ) / 4).toFixed(1));
        }
      );
      console.log(calculatedAnswers,"ygghuhyuh")
      sessionStorage.setItem("calculatedAnswers", JSON.stringify(calculatedAnswers));

      setIsSubmitting(true);
      setTimeout(() => {
        message.success("All questions answered. Proceeding...");
        setFlagquizsubmit(true);
        setIsSubmitting(false);
      }, 1000);
    }
  };

  return (
    <>
      <Header />
      <div className="min-h-screen flex flex-col items-center bg-gradient-to-r from-[#ffffff] to-[#dbeafe] p-6 mt-24">
        <div className="w-full max-w-5xl">
          <div className="bg-[#facc15]  py-4 rounded-lg shadow-md w-full px-6 text-center">
            <p className="font-semibold text-lg">Self-Care Check-In</p>
            <p className="font-normal text-base">Please answer the following questions</p>
          </div>
          <Progress
            percent={((currentIndex + 1) / questions.length) * 100}
            status="active"
            strokeColor="#facc15"
            className="mb-6 mt-4"
          />
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4 }}
          >
            <Card
              bordered={false}
              className="shadow-lg rounded-2xl p-6 bg-White text-center"
            >
              <p className="text-[#4b5563] text-sm mb-3">
                "This short questionnaire helps gather important information about your well-being, allowing us to determine key health metrics. Your responses will help in assessing various aspects of your health and contributing to meaningful evaluations."
              </p>
              <Title level={4} className="text-[#1f2937] text-xl font-semibold mb-3">
                {`Q${currentIndex + 1}) ${questions[currentIndex].question}`}
              </Title>
              <Radio.Group
                onChange={(e) => handleChange(questions[currentIndex].id, e.target.value)}
                value={answers[questions[currentIndex].id]}
                className="flex flex-col space-y-3 mt-4"
              >
                {questions[currentIndex].options.map((option, optIndex) => (
                  <Radio key={optIndex} value={option} className="text-gray-700">
                    {option}
                  </Radio>
                ))}
              </Radio.Group>
            </Card>
          </motion.div>
          <div className="flex justify-end gap-4 mt-6">
            {currentIndex > 0 && (
              <Button
                type="default"
                size="large"
                className="rounded-full px-6 py-2 bg-[#e5e7eb] hover:bg-[#d1d5db]"
                onClick={() => setCurrentIndex(currentIndex - 1)}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              className="rounded-full px-6 py-2 bg-yellow-500 hover:bg-[#eab308] text-white"
              onClick={handleNext}
              loading={isSubmitting}
            >
              {currentIndex < questions.length - 1 ? "Next" : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuizComponent;
