import React, { useState, useEffect } from "react";
import { encodeBase64File } from "./filebase64";
import "./sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { RadioButton } from "primereact/radiobutton";
import { Drawer } from "antd";
import { CameraOutlined, CloudUploadOutlined } from "@ant-design/icons";
import Tickmark from '../../../../Assets/Images/tickmarkgreen.png'

function SideBar(props) {
  const hiddenFileInput = React.useRef(null);
  const hiddenCameraFileInput = React.useRef(null);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [screen, setscreen] = React.useState(window.innerWidth);
  const [successful, setSuccessful] = useState(false);
  const [file, setFile] = useState([]);
  const [showFirst, setShowFirst] = useState(true);
  const [fileDataURL, setFileDataURL] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [radiobutton, setRadiobutton] = useState([]);


  async function handleUpload() {
    if (!file[0].b64File && !file[0].type) {
      setError({
        isNotPhotoUploaded: "Please upload a photo",
      });
      return;
    }

    if (!props.isCart && selectedOption === "") {
      setError({
        isNotOptionNotSelected: "Please choose an option",
      });
      return;
    }

    setLoading(true);

    await props.handleUploadPrescriptionClick(file, radiobutton);

    setLoading(false);
    setShowFirst(false);
  }

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;

      setscreen(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const changeHandler = async (e) => {
    let files = Object.keys(e.target.files).map((res) => e.target.files[res]);
    // let file = e.target.files[0]
    let totalSize = 0;
    // files.forEach((res) => {
    for (let i = 0; i < files.length; i++) {
      let splitvalue = files[i].type.split("/")[1].toUpperCase();
      console.log(splitvalue, "dsiugdsivudvusdvsd");
      if (
        splitvalue !== "PDF" &&
        splitvalue !== "JPG" &&
        splitvalue !== "PNG" &&
        splitvalue !== "JPEG"
      ) {
        setError({
          isNotPhotoUploaded:
            "Please upload JPEG, JPG, PNG and PDF format only",
        });
        return;
      }
      totalSize += files[i].size;
    }

    // });
    console.log(files, "dsvugsdivgdsiuvgdsiuvds");
    if (Number.parseFloat(totalSize / 1000000) > 5) {
      setError({
        isNotPhotoUploaded: "Please upload photo/pdf smaller that 5MB",
      });
      return;
    }
    let splitvalue = [];
    let b64File = [];
    let data = [];
    for (let i = 0; i < files.length; i++) {
      splitvalue = files[i].type.split("/")[1];
      b64File = await encodeBase64File(files[i]);
      data.push({
        file: files[i],
        type: splitvalue,
        size: Number.parseFloat(files[i].size / 1000),
        b64File: b64File,
      });
    }
    // if(file)
    setFile([...file, ...data]);
    setError({
      isNotPhotoUploaded: false,
    });
  };

  useEffect(() => {
    let fileReader = [],
      isCancel = false;
    if (file.length) {
      file.forEach((res) => fileReader.push(readFileAsText(res.file)));
    }

    Promise.all(fileReader).then((values) => {
      console.log(values, "svsdvsdugvsouvs");
      setFileDataURL(values);
    });
  }, [file]);

  function readFileAsText(file) {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();

      fr.onload = function () {
        console.log(fr, "sdvsdiovhsduv");
        resolve(fr.result);
      };

      fr.onerror = function () {
        reject(fr);
      };

      fr.readAsDataURL(file);
    });
  }

  const clicktype = () => {
    hiddenFileInput.current.click();
  };

  const clickcameratype = () => {
    hiddenCameraFileInput.current.click();
  };

  const handleremovePhotoClick = (i) => {
    let fileDataUrl = fileDataURL.filter((res) => res !== fileDataURL[i]);
    setFileDataURL(fileDataUrl);
    let files = [];
    files = file.filter((res, index) => {
      if (index != i) {
        return res;
      }
    });
    console.log(files, "sdvsdvsduvhsd");
    setFile(files);
  };

  const handleDialogClose = () => {
    if (!showFirst) {
      setShowFirst(true);
      props.ToggleSideBar();
      setFileDataURL([]);
      setFile([]);
    } else {
      setShowFirst(true);
      props.ToggleSideBar();
    }
  };

  const handleCloseButtonClick = () => {
    setShowFirst(true);
    props.ToggleSideBar();
    setFileDataURL([]);
    setFile([]);
  };

  const handleRadioButtonChange = (e, index) => {
    console.log(e.checked, "sdvosdihvosdhvds");
    // if(radiobutton.length){
    setRadiobutton({ ...radiobutton, [index]: e.value });
    // } else{
    //   setRadiobutton({ [index]:  e.value})
    // }
    setSelectedOption(e.target.value);
    setError("");
  };

  console.log(radiobutton, "dvsdvbdsovbs");

  return (
    <>
      <div className="bg-White  justify-content-center">
        <Drawer
          visible={props?.isSidebarOpen}
          position={screen > 500 ? "right" : "bottom"}
          blockScroll={true}
          onClose={handleCloseButtonClick}
          onHide={handleCloseButtonClick}
          className={screen > 500 ? "sidebar" : "mobile_sidebar"}
          header={false}
        >
          <h2 className="text-primaryColor font-semibold text-xl">
            Upload Prescription
          </h2>

          {showFirst ? (
            <div>
              <div className="flex gap-2 mt-8">
                <div
                  onClick={() => {
                    clicktype();
                  }}
                  className="w-full mr-5 p-8 border-dashed border-gray border-2 justify-center items-center rounded-lg text-center  cursor-pointer"
                >
                  <CloudUploadOutlined className="text-3xl text-primaryColor" />
                  {/* <input

                                    type="file"
                                    id="file"
                                    accept="application/image;application/pdf"
                                    className="my-1 text-xs hidden"
                                    onClick={changeHandler}
                                    
                                /> */}
                  <label for="file" className="cursor-pointer">
                    <p className="text-textGray">Browse Prescription</p>
                  </label>
                </div>
                <div
                  onClick={() => {
                    clickcameratype();
                  }}
                  className="flex sm:hidden gap-2 p-8 border-dashed border-gray border-2 justify-center items-center rounded-lg text-center"
                >
                  <CameraOutlined className="text-3xl text-primaryColor" /> 
                  <p className="text-black">Take Picture</p>
                </div>
                <input
                  ref={hiddenFileInput}
                  type="file"
                  multiple
                  accept="application/image;application/pdf"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                  className="hidden"
                />
                <input
                  ref={hiddenCameraFileInput}
                  type="file"
                  accept="image/*"
                  id="icon-button-file"
                  multiple
                  capture="environment"
                  className="hidden"
                  onChange={(e) => changeHandler(e)}
                />
              </div>

              {/* //after browse the prescription */}
              <div className="mr-5">
                {fileDataURL?.length
                  ? fileDataURL.map((res, i) => {
                      return (
                        <>
                          <div className="flex mt-10 border border-gray mr-5 rounded-lg p-3">
                            <div className="">
                              <div className="">
                                {/* <img src={res} alt="#" /> */}
                                {file[i].type.toUpperCase() == "PDF" ? (
                                  <iframe
                                    height="150px"
                                    width="100%"
                                    src={res}
                                    alt="#"
                                  />
                                ) : (
                                  <div className="h-[150px] w-full justify-center flex">
                                    <img
                                      className="h-full w-full"
                                      src={res}
                                      alt="#"
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="mt-2 text-center">
                                <div className="flex">
                                  <p className="break-words text-sm">
                                    {file[i].file.name}{" "}|{" "}{file[i].size} Kb
                                  </p>{" "}
                                </div>
                                <button
                                  className="text-danger mt-3 hover:underline"
                                  onClick={() => handleremovePhotoClick(i)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                          {!props.isCart ? (
                            <div className="flex flex-col border-2 mt-2 border rounded-lg p-2">
                              <div className="flex align-items-center">
                                <RadioButton
                                  inputId="convertpres"
                                  className="mt-5"
                                  value="convertpres"
                                  onChange={(e) =>
                                    handleRadioButtonChange(e, i)
                                  }
                                  checked={radiobutton[i] === "convertpres"}
                                />
                                <label
                                  htmlFor="convertprescription"
                                  className="ml-2 mt-5 text-brand-lightgreen "
                                >
                                  Convert Prescription
                                </label>
                              </div>
                              <div className="flex align-items-center">
                                <RadioButton
                                  inputId="original"
                                  className="mt-5"
                                  value="original"
                                  onChange={(e) =>
                                    handleRadioButtonChange(e, i)
                                  }
                                  checked={radiobutton[i] === "original"}
                                />
                                <label
                                  htmlFor="convertprescription"
                                  className="ml-2 mt-5 text-brand-lightgreen "
                                >
                                  Original Prescription
                                </label>
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })
                  : null}

                {error?.isNotOptionNotSelected ? (
                  <div className="text-center text-red-600 text-sm font-medium mt-3">
                    {error?.isNotOptionNotSelected}
                  </div>
                ) : null}

                <button
                 className={`w-full rounded-lg text-White mt-10 p-3 ${
                  file.length === 0
                    ? "bg-gray cursor-not-allowed" // ✅ Gray color for disabled state
                    : "bg-primaryColor hover:bg-blue-700"
                }`}
                disabled={loading || file.length === 0}
                  // disabled={loading}
                  // className="bg-primaryColor w-full rounded-lg text-White mt-10  p-3 "
                  onClick={handleUpload}
                >
                  Upload{" "}
                  {loading && (
                    <div className="loader float-right ml-2 ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                  )}{" "}
                </button>
                {error?.isNotPhotoUploaded ? (
                  <div className="text-center text-red-600 text-sm font-medium">
                    {error?.isNotPhotoUploaded}
                  </div>
                ) : null}
              </div>

              <h3 className="text-textGray text-base font-semibold mt-8">
                Guide for a valid prescription
              </h3>
              <div className="flex flex-col p-4 gap-4">
                <div className="flex text-textGray">
                  <p>&#10095; Don't crop out any part of the image</p>
                </div>
                <div className="flex text-textGray">
                  <p>&#10095; Avoid blurred image</p>
                </div>
                <div className="flex text-textGray">
                  <p>&#10095; Supported files: jpeg , jpg , png , pdf</p>
                </div>
                <div className="flex text-textGray">
                  <p>&#10095; Maximum allowed file size: 5MB</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-5 text-center mt-10 justify-center content-center items-center">
                <img
                  src={Tickmark}
                  className="h-14 w-14"
                  alt="#"
                />
                <p className="text-2xl font-bold text-black">
                  Prescription Uploaded
                </p>
                <p className="text-black">
                  You have successfully uploaded your prescription
                </p>
                <button
                  onClick={handleCloseButtonClick}
                  className="bg-blue-600 w-full rounded-lg text-White mt-10 p-3 btncss"
                >
                  Close
                </button>
              </div>
              {!props?.isCart ? (
                <>
                  {" "}
                  <p className="text-xl mt-7 mb-4 ">What's Next?</p>
                  <ul
                    role="list"
                    class="marker:text-sky-400 list-disc pl-5 space-y-3 text-slate-400"
                  >
                    <li className="text-black">
                      {" "}
                      A SMS / Whatsapp message will be sent to your registered
                      phone number once the cart is ready for review and order.
                    </li>
                    <li className="text-black">
                      {" "}
                      Once you receive the notification, please go to "Medicine"
                      page then go to "Saved Prescriptions" for ordering
                      medicines and lab tests
                    </li>
                  </ul>{" "}
                </>
              ) : null}
            </>
          )}
        </Drawer>
      </div>
    </>
  );
}

export default SideBar;
