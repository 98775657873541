import React, { useEffect, useMemo, useState } from "react";
import { Chart } from "primereact/chart";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const Graph = ({ title, field, fromDate,
  setFromDate,
  toDate,
  setToDate,
  graphData, }) => {

    console.log( fromDate,
      setFromDate,
      toDate,
      setToDate,
      "edrfgtyhjui")
      console.log(graphData,"rdfgvfg")


  const lastWeekStart = moment().subtract(7, "days").format("YYYY-MM-DD");
  // const lastWeekEnd = moment().subtract(1, "days").format("YYYY-MM-DD");
  const lastWeekEnd =moment().format("YYYY-MM-DD");  

  // const [fromDate, setFromDate] = useState(lastWeekStart);
  // const [toDate, setTodate] = useState(lastWeekEnd);

  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
  // const [graphData, setgraphData] = useState([]);

  console.log(lastWeekStart, lastWeekEnd, fromDate, toDate, "gggyiuu")



  const filterDataByDateRange = (data, startDate, endDate) => {
    if (!startDate || !endDate) return data;

    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(23, 59, 59, 999);

    return data.filter((item) => {
      const itemDate = new Date(item.CREATEDDATE).getTime();
      return itemDate >= start && itemDate <= end;
    });
  };

  const chartData = useMemo(() => {
    if (!graphData || graphData.length === 0) return null;

    const filteredData = filterDataByDateRange(graphData, fromDate, toDate)
      .filter((item) => item[field] !== null)
      .map((item) => ({
        x: new Date(item.CREATEDDATE),
        y: item[field],
      }));

    return {
      labels: filteredData.map((entry) =>
        entry.x.toLocaleDateString([], { year: "numeric", month: "short", day: "numeric" })
      ),
      datasets: [
        {
          label: title,
          data: filteredData.map((entry) => entry.y),
          fill: true,
          backgroundColor: "rgba(76, 175, 80, 0.2)",
          borderColor: "rgba(76, 175, 80, 1)",
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 4,
          pointBackgroundColor: "rgba(76, 175, 80, 1)",
        },
      ],
    };
  }, [graphData, field, title, fromDate, toDate]);
  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          backgroundColor: "#fff",
          borderColor: "rgba(76, 175, 80, 1)",
          borderWidth: 1,
          titleColor: "#000",
          bodyColor: "#000",
          titleFont: { weight: "bold" },
          bodyFont: { size: 14 },
          callbacks: {
            title: (tooltipItems) => {
              const date = new Date(graphData[tooltipItems[0].dataIndex].CREATEDDATE);
              return date.toLocaleDateString();
            },
            label: (tooltipItem) => ` ${tooltipItem.raw} `,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
        y: {
          // title: {
          //   display: true,
          //   text: title,
          //   font: { weight: "bold", size: 14 },
          //   color: "#000",
          // },
          ticks: { color: "#444" },
          grid: { color: "rgba(0, 0, 0, 0.1)", borderDash: [4, 4] },
        },
      },
    }),
    [graphData, title]
  );


  const handlefromDate = (date) => {
    if (!date) return;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setFromDate(formattedDate);

  };

  const handleToDate = (date) => {
    if (!date) return;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setToDate(formattedDate);

  };

  return (
    <div
      style={{
        width: "100%",
        // height: "400px",
        padding: "15px",
        background: "white",
        borderRadius: "10px",
        marginBottom: "20px",
      }}
    >
      <div className="flex justify-end">
        {/* <h2
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#4caf50",
          }}
        >
          {title} Over Time
        </h2> */}


        <div className="flex gap-4 " >
          <Calendar
            value={fromDate ? new Date(fromDate) : null}
            onChange={(e) => handlefromDate(e.value)}
            dateFormat="yy-mm-dd"
            placeholder="From Date"
            className="border border-White border-b-gray rounded-md p-2"
            showIcon
          />
          <Calendar
            value={toDate ? new Date(toDate) : null}
            onChange={(e) => handleToDate(e.value)}
            dateFormat="yy-mm-dd"
            placeholder="To Date"
            className="border border-White border-b-gray rounded-md p-2"
            showIcon
          />
        </div>
      </div>
      <div className="mt-10">
        {chartData ? (
          <Chart type="line" data={chartData} options={chartOptions} />
        ) : (
          <p style={{ textAlign: "center", color: "#999" }}>No data available</p>
        )}
      </div>
    </div>
  );
};

export default Graph;
