import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientDetails } from "../../../../Redux/Action/UserprofileActions";
import singlecardodia from "../../../../Assets/Images/CureBayCard/ODIA/SINGLE CARD_Odia.png";
import twincardodia from "../../../../Assets/Images/CureBayCard/ODIA/TWIN CARD_Odia.png";
import familycardodia from "../../../../Assets/Images/CureBayCard/ODIA/FAMILY CARD_Odia.png";
import singlecardhindi from "../../../../Assets/Images/CureBayCard/HINDI/SINGLE CARD _Hindi.png";
import twincardhindi from "../../../../Assets/Images/CureBayCard/HINDI/TWIN CARD_HINDI.png";
import familycardhindi from "../../../../Assets/Images/CureBayCard/HINDI/FAMILY CARD_ Hindi.png";
import singlecardenglish from "../../../../Assets/Images/CureBayCard/ENGLISH/SINGLE CARD _ Eng.png";
import twincardenglish from "../../../../Assets/Images/CureBayCard/ENGLISH/TWIN CARD_Eng.png";
import familycardenglish from "../../../../Assets/Images/CureBayCard/ENGLISH/FAMILY CARD_ENG.png";
import diabeticeng from "../../../../Assets/Images/CureBayCard/Diabetic/diabetic_care_eng.jpg";

function Membershipcarddownload(props) {
  const dispatch = useDispatch();
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [isLoading, setisLoading] = useState(false);

  // Mapping object for images
  const membershipImages = {
    Single: {
      OD: singlecardodia,
      CG: singlecardhindi,
      default: singlecardenglish,
    },
    Twin: {
      OD: twincardodia,
      CG: twincardhindi,
      default: twincardenglish,
    },
    Family: {
      OD: familycardodia,
      CG: familycardhindi,
      default: familycardenglish,
    },
    "Diabetes Care": {
      default: diabeticeng, // Display Diabetic Care image when membership is "Diabetes Care"
    },
  };


  // const getImage = (membership, patientState) => {
  //   return (
  //     membershipImages[membership]?.[patientState] ||
  //     membershipImages[membership]?.default
  //   );
  // };
  const getImage = (membership, patientState) => {
    if (membership === "Diabetes Care") {
      return diabeticeng; 
    }
    return (
      membershipImages[membership]?.[patientState] ||
      membershipImages[membership]?.default
    );
  };

  useEffect(() => {
    if (props.data) {
      setisLoading(true);
      dispatch(getPatientDetails(props.data.patientCode)).then(() => {
        setisLoading(false);
      });
    }
  }, [props.data, dispatch]);

  const download = (membership, patientState) => {
    const imageUrl = getImage(membership, patientState);

    if (!imageUrl) {
      console.error("Invalid Membership Type or Patient State");
      return;
    }

    const sanitizedPatientName = props.data.patientName
      .replace(/\s+/g, "_")
      .replace(/[^a-zA-Z0-9_]/g, "");

    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `${sanitizedPatientName}-membership-Card.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log(props.data,"dfvgbh")

  return (
    <>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          {props.data && (
            <div id="abc">
              <div className="text-center m text-2xl text-secondaryColor font-bold pl-4 uppercase">
                {props.data.membership} Membership
              </div>
              <div className="flex justify-center">
                <img
                  className="w-3/4 ml-10"
                  src={getImage(props.data.membership, props.data.patientState)}
                  alt={`${props.data.membership} Membership`}
                />
              </div>
            </div>
          )}

          <div className="flex justify-center my-10">
            <button
              onClick={() =>
                download(props.data.membership, props.data.patientState)
              }
              className="cursor-pointer text-sm font-normal font-rubik rounded-lg bg-secondaryColor text-White py-3 px-8"
            >
              Download Card
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default Membershipcarddownload;
