import React from "react";

export default function CurebayKavach({ data }) {
  console.log("DataCureBayKavach", data?.Kavach?.[0]?.image_url);

  const redirectToMeds = () => {
    const baseUrl = window.location.origin;
    const redirectPath = "/medicine/CureBay-Kavach-First-Aid-Kit--CB254376";
    window.location.href = `${baseUrl}${redirectPath}`;
  };

  return (
    <section className="home-banner bg-White">
      {/* Web Banner (Visible Only on Web) */}
      <div className="hidden sm:flex relative w-full flex-col items-center justify-end object-contain">
        <div
          className="w-[90%] h-full cursor-pointer mx-auto rounded-lg"
          onClick={redirectToMeds}
        >
         <video
  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20New%20/First-Aid%20Kit%20Video.mp4"
  className="w-full h-full object-contain"
  autoPlay
  muted
  loop
  playsInline
  controls 
>
  Your browser does not support the video tag.
</video>

        </div>
      </div>

      {/* Mobile Banner (Visible Only on Mobile) */}
      <div className="block sm:hidden">
        <div className=" w-full h-[100px]" onClick={redirectToMeds}>
          <video
            src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20New%20/First-Aid%20Kit%20Video.mp4"
            className="w-full h-full object-contain"
            autoPlay
            muted
            loop
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
}
