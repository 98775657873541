import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartTimeLine from "../cart/CartTimeLine";
import Address from "./Address";
import UploadPrescription from "./UploadPrescription";
import {
  getCartData,
  getUploadedPrescription,
  getEPrescrptionList,
  uploadPrescriptionDoc,
  deleteUploadedPrescripiton,
} from "./cartApi/cartapis";
import SideBar from "./cartModels/SideBar";
import EPrescriptionListModel from "./cartModels/EPrescriptionListModel";
import SavedPrescriptionListModel from "./cartModels/SavedPrescriptionListModel";
import ViewUploadedPrescriptionModel from "./cartModels/ViewUploadedPrescriptionModel";
import http from "../../../Redux/Services/http-common";
import CartPrice from "./cartPrice";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import Layouts from "./../../Layouts";
import { trackCheckout } from "../../utils/analytics/events";
import { getSessionStorageWithExpiry } from "../../Helper/sessionStorageWithExpiry";
import PreferedDateAndTime from "./PreferedDateAndTime";
import moment from "moment";
import { Spin } from "antd";
import LoaderOverlay from "../../Shared/cartLoader";
const Checkout = () => {
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.patientData);
  // const selectpatientdata = location.state.patient;
  const [uploadedPrescriptionList, setUploadedPrescriptionList] = useState({
    prescriptionUploaded: false,
    prescriptionDoc: [],
    docType: "",
    loading: false,
  });
  const [cartData, setCartData] = useState({});
  const [ePrescriptionList, setEPrescriptionsList] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEPrescriptionDialogOpen, setIsEPrescriptionDialogOpen] =
    useState(false);
  const [isSavedPrescriptionDialogOpen, setIsSavedPrescriptionDialogOpen] =
    useState(false);
  const [selectedEPrescription, setSelectedEPrescription] = useState([]);
  const [savedPrescriptionList, setSavedPrescriptionList] = useState([]);
  const [selectedSavedPrescription, setSelectedSavedPrescription] = useState(
    []
  );
  const [prescriptionUrl, setPrescriptionUrl] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [preferDate, setPreferDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [cartDatalengthy, setCartDatalength] = useState();
  const [preferTime, setPreferTime] = useState("");
  useEffect(() => {
    handlegetCartData();
  }, []);

  useEffect(() => {
    console.log(JSON.stringify(localStorage.getItem("preferTime")));
    if (JSON.stringify(localStorage.getItem("preferTime"))) {
      setPreferTime(JSON.stringify(localStorage.getItem("preferTime")));
    }
  }, []);

  useEffect(() => {
    // if(!JSON.parse(localStorage.getItem("preferTime"))){
    //   history.push('/cart')
    // } else if(!JSON.parse(localStorage.getItem("preferValue"))){
    //   history.push('/cart')
    // } else  if(!JSON.parse(localStorage.getItem("preferDate"))){
    //   history.push('/cart')
    // }
  }, []);

  useEffect(() => {
    getUploadedPrescriptionHandle();
  }, [cartData]);

  useEffect(() => {
    let prescptionDoc = [];
    http
      .get(
        `${process.env.REACT_APP_BASEURL}PatientDocument/list/filter?status=1&patientCode=${userData.code}&documentRequired=Y`
      )
      .then((res) => {
        res.data.forEach((item) => {
          if (item.visitId != 0) {
            http
              .get(
                `${process.env.REACT_APP_BASEURL}Visit/list?id=${item.visitId}&visitSummary=Y`
              )
              .then((result) => {
                prescptionDoc.push(result.data);
              });
          } else if (!item.conversionNeeded) {
            prescptionDoc.push([item]);
          }
        });
        setSavedPrescriptionList(prescptionDoc);
      })
      .catch((err) => {});
  }, [userData]);
  // const handlegetCartData = () => {
  //   // Fetch cart data here (example)
  //   // Replace this with your actual data fetching logic
  //   setTimeout(() => {
  //     const fetchedCartData = { price: 100 }; // Replace with actual cart data
  //     setCartData(fetchedCartData);
  //     setLoading(false);
  //   }, 2000); // Simulating a 2-second delay for data fetching
  // };

  useEffect(() => {
    handlegetCartData();
  }, []);
  const handlegetCartData = () => {
    setIsloading(true); // Show spinner
    getCartData(userData.code)
      .then((res) => {
        if (res.data?.length) {
          setCartData(res.data[0]);
          const prescriptionRequiredCount = res.data[0]?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList?.filter( 
                (item) => item.prescriptionRequired === "Y")?.length || 0;
                console.log(prescriptionRequiredCount,"ftghbj")
          setCartDatalength(prescriptionRequiredCount)
        }
      })
      .catch((err) => {
        console.error("Error fetching cart data:", err);
      })
      .finally(() => {
        setIsloading(false); // Hide spinner after data is loaded or if there's an error
      });
  };

  const checkPrescriptionRequired = () => {
    if (cartData?.patientMedicineOrder?.length) {
      let list =
        cartData?.patientMedicineOrder[0].patientMedicineOrderDetailsList?.map(
          (res) => res
        );
      let uploadPres = list?.filter(
        (element) => element.prescriptionRequired == "Y"
      );
      if (uploadPres?.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getUploadedPrescriptionHandle = () => {
    if (!cartData?.patientMedicineOrder?.length) {
      return;
    }
    let query = {
      orderId: cartData.patientMedicineOrder[0].orderId,
      orderType: "drugOrder",
    };
    getUploadedPrescription(query).then((getres) => {
      if (getres.data.length) {
        if (getres?.data[0]?.prescriptionDocName) {
          let docType = getres?.data[0]?.prescriptionDocName?.split(".")[1];
          let docName = getres?.data[0]?.prescriptionDocName?.split(",");
          localStorage.setItem("prescriptionDoc", JSON.stringify(docName));
          setUploadedPrescriptionList({
            prescriptionUploaded: true,
            prescriptionDoc: docName,
            docType: docType,
          });
        } else {
          setUploadedPrescriptionList({
            prescriptionUploaded: false,
            prescriptionDoc: [],
            docType: "",
            loading: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    let payload = {
      patientId: userData.code,
      visitSummary: "Y",
      status: 1,
      documentRequired: "Y",
    };
    getEPrescrptionList(payload).then(
      (res) => {
        if (res.data?.length) {
          let dataForPrescription = res.data.map((res1) => {
            if (res1.patientDrugPrescriptionList?.length) {
              return res1.patientDrugPrescriptionList.map((res2) => {
                return {
                  ...res2,
                  ...res1,
                };
              });
            }
          });
          let result = dataForPrescription.filter((item) =>
            Array.isArray(item)
          );

          setEPrescriptionsList(result);
        }
      },
      (err) => {}
    );
  }, []);

  const handleUploadPrescriptionClick = (Incomingfiles, type = "drugOrder") => {
    let files = Object.keys(Incomingfiles).map((res) => Incomingfiles[res]);
    let totalSize = 0;
    files.forEach((res) => {
      totalSize += res.size;
    });
    setUploadedPrescriptionList({
      loading: true,
    });
    let cart = cartData;
    cart.drugsOrdersYN = true;
    let docCollection = [];
    files.forEach((res) => {
      docCollection.push({
        prescriptionDoc: res.b64File,
        prescriptionDocType: res.type,
      });
    });
    cart.patientMedicineOrder[0].prescriptionDocCollection = docCollection;
    cart.patientMedicineOrder[0].savedImagesKeys = null;
    uploadPrescriptionDoc(cart.patientMedicineOrder[0].orderId, cart)
      .then((res) => {
        let param = {
          orderId: cart.patientMedicineOrder[0].orderId,
          orderType: "drugOrder",
        };
        getUploadedPrescription(param)
          .then((getres) => {
            if (getres.data.length) {
              let docType = getres.data[0].prescriptionDocName.split(".")[1];
              let docName = getres?.data[0]?.prescriptionDocName?.split(",");
              localStorage.setItem("prescriptionDoc", JSON.stringify(docName));
              setUploadedPrescriptionList({
                prescriptionUploaded: true,
                prescriptionDoc: docName,
                docType: docType,
                loading: false,
              });
              handlegetCartData();
            }
            // return true;
          })
          .catch((err) => {
            setUploadedPrescriptionList({
              loading: false,
            });
          });
      })
      .catch((err) => {
        setUploadedPrescriptionList({
          loading: false,
        });
      });
  };

  const ToggleSideBar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleEPrescriptionModelToggleClick = () => {
    setIsEPrescriptionDialogOpen(!isEPrescriptionDialogOpen);
  };

  const handleSavedPrescriptionModelToggleClick = () => {
    setIsSavedPrescriptionDialogOpen(!isSavedPrescriptionDialogOpen);
  };

  const handleEPrescriptionSelectClick = (key, checked, base64) => {
    if (selectedEPrescription.length && checked) {
      setSelectedEPrescription([...selectedEPrescription, { key, base64 }]);
    } else if (selectedEPrescription.length && !checked) {
      let update = selectedEPrescription.filter((res) => res.key != key);
      setSelectedEPrescription(update);
    } else {
      setSelectedEPrescription([
        {
          key,
          base64,
        },
      ]);
    }
  };

  const handleSelectPrescriptionClick = (type) => {
    let cart = cartData;
    let docCollection = [];
    let savedImagesKeys = [];
    if (type == "savePrescription") {
      selectedSavedPrescription.forEach((res) => {
        if (res.image) {
          savedImagesKeys.push(res.image);
        } else {
          docCollection.push({
            prescriptionDoc: res.base64,
            prescriptionDocType: "pdf",
          });
        }
      });
    } else if (type == "ePrescription") {
      selectedEPrescription.forEach((res) => {
        docCollection.push({
          prescriptionDoc: res.base64,
          prescriptionDocType: "pdf",
        });
      });
    }
    cart.patientMedicineOrder[0].prescriptionDocCollection = docCollection;
    cart.patientMedicineOrder[0].savedImagesKeys = savedImagesKeys.join();

    uploadPrescriptionDoc(cart.patientMedicineOrder[0].orderId, cart)
      .then((res) => {
        let param = {
          orderId: cart.patientMedicineOrder[0].orderId,
          orderType: "drugOrder",
        };
        getUploadedPrescription(param)
          .then((getres) => {
            if (getres.data.length) {
              let docType = getres.data[0].prescriptionDocName.split(".")[1];
              let docName = getres?.data[0]?.prescriptionDocName?.split(",");
              localStorage.setItem("prescriptionDoc", JSON.stringify(docName));
              setUploadedPrescriptionList({
                prescriptionUploaded: true,
                prescriptionDoc: docName,
                docType: docType,
                loading: false,
              });
              handlegetCartData();
            }
            setIsSavedPrescriptionDialogOpen(false);
            setIsEPrescriptionDialogOpen(false);
            setSelectedSavedPrescription([]);
            setSelectedEPrescription([]);
          })
          .catch((err) => {
            setUploadedPrescriptionList({
              loading: false,
            });
            setIsSavedPrescriptionDialogOpen(false);
            setIsEPrescriptionDialogOpen(false);
            setSelectedSavedPrescription([]);
            setSelectedEPrescription([]);
          });
      })
      .catch((err) => {
        setUploadedPrescriptionList({
          loading: false,
        });
      });
  };



 

  const handlePrescriptionSelectClick = (key, checked, base64) => {
    if (selectedSavedPrescription.length && checked) {
      setSelectedSavedPrescription([
        ...selectedSavedPrescription,
        { key, base64 },
      ]);
    } else if (selectedSavedPrescription.length && !checked) {
      let update = selectedSavedPrescription.filter((res) => res.key != key);
      setSelectedSavedPrescription(update);
    } else {
      setSelectedSavedPrescription([
        {
          key,
          base64,
        },
      ]);
    }
  };

  const handleOriginalPrescriptionSelectClick = (key, checked, image) => {
    if (selectedSavedPrescription.length && checked) {
      setSelectedSavedPrescription([
        ...selectedSavedPrescription,
        { key, image },
      ]);
    } else if (selectedSavedPrescription.length && !checked) {
      let update = selectedSavedPrescription.filter((res) => res.key != key);
      setSelectedSavedPrescription(update);
    } else {
      setSelectedSavedPrescription([
        {
          key,
          image,
        },
      ]);
    }
  };

  const handleSavePrescriptionClick = () => {
    setIsSavedPrescriptionDialogOpen(true);
  };

  const ViewPrescription = (res) => {
    setPrescriptionUrl(res);
    setOpen(true);
  };

  const handlePrescriptionDelete = (e, res) => {
    e.stopPropagation();
    let param = {
      orderId: cartData.patientMedicineOrder[0].orderId,
      keysForDelete: res,
    };
    deleteUploadedPrescripiton(param).then((res) => {
      handlegetCartData();
    });
    // /dhp/Prescription/deletePrescription?orderId=121932&keysForDelete=abc.jpg,sdf.pdf
  };

  const filteredOrders = cartData?.patientMedicineOrder?.filter(order =>  
    !order.patientMedicineOrderDetailsList?.some(detail => detail.drugCode === "CB254376")
  );

  const medicineList = cartData?.patientMedicineOrder?.[0]?.patientMedicineOrderDetailsList || [];

  const isDrugCodePresent = medicineList.some(detail => detail.drugCode === "CB254376");
  

  console.log(cartDatalengthy,isDrugCodePresent,"rdftg")

  const goToReviewPage = async () => {
    const value = getSessionStorageWithExpiry(
      "doctorAppointentForPrescription"
    );
    if (value || (cartDatalengthy == 1  && isDrugCodePresent === true)) {
    } else if (
      checkPrescriptionRequired() &&
      uploadedPrescriptionList.prescriptionDoc?.length == 0
    ) {
      toast("Prescription is required to order.");
      return;
    }

    let address = JSON.parse(localStorage.getItem("selectedAddress"));
    if (!address) {
      toast("Please select address");
      return;
    }
    if (!address.lattitude || !address.longitude) {
      toast("Address is not complete, Please edit or add new address.");
      return;
    }

    if (cartData?.patientLabTestsOrder?.length) {
      if (!JSON.parse(localStorage.getItem("preferTime"))) {
        toast("Please select preferred date and time.");
        return;
      } else if (!JSON.parse(localStorage.getItem("preferValue"))) {
        toast("Please select preferred date and time.");
        return;
      } else if (!JSON.parse(localStorage.getItem("preferDate"))) {
        toast("Please select preferred date and time.");
        return;
      }
    }

    try {
      if (cartData?.patientMedicineOrder?.length) {
        const data = await http.get(
          `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkService?pinCode=${address.pinCode}`
        );
        if (data.data.status !== 200) {
          toast("We can't deliver medicine order to selected address.");
          return;
        }
      }
      if (cartData?.patientLabTestsOrder?.length) {
        const data = await http.get(
          `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailability?locationId=${cartData?.patientLabTestsOrder[0].locationId}&pinCode=${address.pinCode}`
        );
        console.log(cartData?.patientLabTestsOrder, "Sdvksdvodshgvousdvsddsv");
        if (data.data.status !== 200) {
          toast("We don't have Lab partner nearby.");
          return;
        }
      }
    } catch (error) {
      toast("Something went wrong");
      return;
    }
    history.push("/cart/review");
    if (
      cartData?.patientMedicineOrder?.length &&
      cartData?.patientLabTestsOrder?.length
    ) {
      trackCheckout("checkout_medicine_labtest");
    } else if (cartData?.patientMedicineOrder?.length) {
      trackCheckout("checkout_medicine", [
        ...cartData.patientMedicineOrder[0].patientMedicineOrderDetailsList,
      ]);
    } else if (cartData?.patientLabTestsOrder?.length) {
      trackCheckout("checkout_labtest", [
        ...cartData.patientLabTestsOrder[0].patientLabTestsOrderDetailsList,
      ]);
    }
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
    // history.push({
    //   pathname: '/cart/review',
    //   state:{ selectpatientdata:selectpatientdata}

    // });
  };

  const handleAppointmentClick = () => {
    if (userData?.sessionid) {
      // (e) => redirectTo(e, APP_ROUTES.UPLOADED_PRESCRIPTION)
      history.push({ pathname: APP_ROUTES.DOCTORS });
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
  };

  const convertTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}`;
  };

  const handleChange = (e) => {
    let value = e?.target?.value;
    setPreferTime(value);

    let time = convertTime(value.substring(0, 5));
    localStorage.setItem("preferTime", JSON.stringify(time));
    localStorage.setItem("preferValue", JSON.stringify(value));
    localStorage.setItem("preferDate", JSON.stringify(preferDate));
  };

  const limitTimeForLab = (timeParam) => {
    console.log(timeParam, "sdpfdibdhpisdhpb");
    if (moment(preferDate).isSame(moment().format("YYYY-MM-DD"))) {
      let current_time_in_hour = moment().hour();

      let am_or_pm = timeParam.value.substring(0, 5).split(" ")[1];
      let time_in_hours = Number(timeParam.value.substring(0, 5).split(" ")[0]);
      if (am_or_pm == "PM" && time_in_hours != 12) {
        time_in_hours += 12;
      }
      console.log(
        "current_time_in_hour:",
        current_time_in_hour + 4,
        "time_in_hours: ",
        time_in_hours
      );
      if (current_time_in_hour + 4 > time_in_hours) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const changeDate = (date) => {
    setPreferDate(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"));

    date = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");
  };
 
 

  
  return (
    <>
      {isLoading && (
       <LoaderOverlay isLoading={isLoading} />
      )}

      {!isLoading && (
        <Layouts>
          <ToastContainer />
          <section className="bg-lightGray md:py-8 py-6 md:px-28">
            <div className="mb-5">
              <CartTimeLine />
            </div>
            <div className="md:flex gap-5">
              <div className="md:w-[65%] md:min-h-[70vh]  h-full">
                <Address cartId={cartData?.cartId} />
                {cartData?.patientLabTestsOrder?.length > 0 && (
                  <div className="bg-White p-8 rounded-lg mt-5 md:px-24">
                    <div className="text-sm w-full text-primaryColor font-semibold text-center mb-4">
                      Select your preferred Date and Time for{" "}
                      {cartData?.patientLabTestsOrder[0]
                        ?.patientLabTestsOrderDetailsList[0]?.labTestType ===
                      "Radiology"
                        ? "your radiology appointment"
                        : "sample collection"}
                    </div>
                    <PreferedDateAndTime
                      preferDate={preferDate}
                      preferTime={preferTime}
                      changeDate={changeDate}
                      labStartTime={
                        cartData?.patientLabTestsOrder[0].labStartTime
                      }
                      labEndTime={cartData?.patientLabTestsOrder[0].labEndTime}
                      labType={
                        cartData?.patientLabTestsOrder[0]
                          ?.patientLabTestsOrderDetailsList[0]?.labTestType
                      }
                      handleChange={handleChange}
                      limitTimeForLab={limitTimeForLab}
                    />
                  </div>
                )}
                {cartDatalengthy == 1  && isDrugCodePresent === true ?
                "":
                <div className="bg-White p-8 rounded-lg mt-5">
 
                <UploadPrescription
                  checkPrescriptionRequired={checkPrescriptionRequired}
                  uploadedPrescriptionList={uploadedPrescriptionList}
                  ePrescriptionList={ePrescriptionList}
                  ToggleSideBar={ToggleSideBar}
                  handleEPrescriptionModelToggleClick={
                    handleEPrescriptionModelToggleClick
                  }
                  savedPrescriptionList={savedPrescriptionList}
                  handleSavePrescriptionClick={handleSavePrescriptionClick}
                  patientMedicineOrder={cartData?.patientMedicineOrder ?? [0]}
                  ViewPrescription={ViewPrescription}
                  handlePrescriptionDelete={handlePrescriptionDelete}
                  handleAppointmentClick={handleAppointmentClick}
                />

              </div>
                }
                               {/* {cartDatalengthy !== 1  && isDrugCodePresent
   && (

   
                <div className="bg-White p-8 rounded-lg mt-5">
 
                  <UploadPrescription
                    checkPrescriptionRequired={checkPrescriptionRequired}
                    uploadedPrescriptionList={uploadedPrescriptionList}
                    ePrescriptionList={ePrescriptionList}
                    ToggleSideBar={ToggleSideBar}
                    handleEPrescriptionModelToggleClick={
                      handleEPrescriptionModelToggleClick
                    }
                    savedPrescriptionList={savedPrescriptionList}
                    handleSavePrescriptionClick={handleSavePrescriptionClick}
                    patientMedicineOrder={cartData?.patientMedicineOrder ?? [0]}
                    ViewPrescription={ViewPrescription}
                    handlePrescriptionDelete={handlePrescriptionDelete}
                    handleAppointmentClick={handleAppointmentClick}
                  />

                </div>
                  )} */}
              </div>

              <div className="md:w-[35%] md:min-h-[70vh]  h-full bg-White p-8 rounded-lg">
                <CartPrice
                  handleProceed={goToReviewPage}
                  cartData={cartData}
                  getCartData={handlegetCartData}
                  patient={userData?.code}
                  readOnly={true}
                  buttonText="Review"
                />
              </div>
              <SideBar
              isSidebarOpen={isSidebarOpen}
              handleUploadPrescriptionClick={handleUploadPrescriptionClick}
              ToggleSideBar={ToggleSideBar}
              isCart={true}
            />
            <EPrescriptionListModel
              isOpen={isEPrescriptionDialogOpen}
              data={ePrescriptionList}
              handleClose={handleEPrescriptionModelToggleClick}
              handleEPrescriptionSelectClick={handleEPrescriptionSelectClick}
              handleSelectPrescriptionClick={handleSelectPrescriptionClick}
            />
            <SavedPrescriptionListModel
              isOpen={isSavedPrescriptionDialogOpen}
              data={savedPrescriptionList}
              handleClose={handleSavedPrescriptionModelToggleClick}
              handleSelectPrescriptionClick={handleSelectPrescriptionClick}
              handlePrescriptionSelectClick={handlePrescriptionSelectClick}
              handleOriginalPrescriptionSelectClick={
                handleOriginalPrescriptionSelectClick
              }
            />
            {prescriptionUrl && (
              <ViewUploadedPrescriptionModel
                isOpen={isOpen}
                prescriptionUrl={prescriptionUrl}
                handleClose={() => setOpen(false)}
              />
            )}
            </div>
          </section>
        </Layouts>
      )}
    </>
  );
};

export default Checkout;
