import React, { useState, useEffect } from "react";
import { Spin } from "antd";

const LoaderOverlay = ({ isLoading }) => {
  const messages = [
    "Hold on, we're caring for your health needs.",
    "Sit tight, our experts are preparing your experience.",
    "Your well-being is our top priority—just a moment!",
    "Great things are coming your way... please wait.",
    "Almost there, magic is happening behind the scenes.",
    "Uhmm!!,Seems like it's taking more than usual",
  ];

  // State to keep track of the current message index
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isLoading, messages.length]);

  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-black bg-opacity-30 z-50 text-white">
      <Spin size="large" />
      <p className="mt-4 text-lg font-semibold text-primaryColor">
        {messages[messageIndex]}
      </p>
    </div>
  );
};

export default LoaderOverlay;
