import React, { useState, useEffect, useRef } from "react";
import Layouts from "../../Layouts";
import PersonalizedAssistant from "../../../Assets/Images/Icons/PersonalizedAssistant.svg";
import EmotionalSupport from "../../../Assets/Images/Icons/EmotionalSupport.svg";
import Continuous from "../../../Assets/Images/Icons/Continuous.svg";
import Comperhensive from "../../../Assets/Images/Icons/Comperhensive.svg";
import PieceMind from "../../../Assets/Images/Icons/PieceMind.svg";
import SubHeader from "../../Shared/SearchBar";
import dentalcareimg1 from "../../../Assets/Images/dentalcare/dentalcareimg1.png";
import dentalcareimg2 from "../../../Assets/Images/dentalcare/dentalcareimg2.png";
import dentalcareimg3 from "../../../Assets/Images/dentalcare/dentalcareimg3.png";
import dentalcareimg4 from "../../../Assets/Images/dentalcare/dentalcareimg4.png";
import dentalcareimg5 from "../../../Assets/Images/dentalcare/Gum.png";
import dentalcareimg6 from "../../../Assets/Images/dentalcare/innercheek.png";
import dentalcareimg7 from "../../../Assets/Images/dentalcare/tongue.png";
import dentalcareimg8 from "../../../Assets/Images/dentalcare/lips.png";
import dentalcareimg2nd from "../../../Assets/Images/dentalcare/oralcare_image.svg";
import HIW1 from "../../../Assets/Images/smartVitals/howitworks-1.svg";
import HIW2 from "../../../Assets/Images/dentalcare/ai_scan.svg";
import HIW3 from "../../../Assets/Images/smartVitals/howitworks-3.svg";
import HIW4 from "../../../Assets/Images/dentalcare/get_recommendation.svg";
import { Collapse } from "antd";
import Arrowimage from "../../../Assets/Images/Icons/linearrow.png";
import http from "../../../Redux/Services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { checkIsLoggedIn } from "../../../Redux/Action/userActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";

import { Dialog } from 'primereact/dialog';
        


import sucessvectoricon from "../../../Assets/Images/sucessvectoricon.svg";

import { Spin } from "antd";
import { useApiData } from "../../../Context/ApidtaContext";
const { Panel } = Collapse;

const faqData = {
  faq: [
    {
      question: "Crowding of teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Open bite",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Protruded teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Cross bite",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Under bite",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Missing teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Deep bite teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
  ],
};

const CustomPanelHeader = ({ headerText }) => {
  return (
    <div className="flex gap-4 items-center">
      {/* <img src={Arrowimage} alt="images" className="h-[20px]" /> */}
      <span className="text-[#373737] sm:text-2xl text-lg font-medium">
        {headerText}
      </span>
    </div>
  );
};
const encodeBase64File = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

function Dentalcare() {
  const hiddenFileInput1 = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const hiddenFileInput3 = useRef(null);
  const hiddenFileInput4 = useRef(null);
  const hiddenFileInput5 = useRef(null);
  const hiddenFileInput6 = useRef(null);
  const hiddenFileInput7 = useRef(null);
  const userData = useSelector((state) => state.authReducer.patientData);
  console.log(userData, "iuhklnlk");
  const [error, setError] = useState(null);
  const [b64File1, setB64File1] = useState("");
  const [file1, setFile1] = useState("");
  const [b64File2, setB64File2] = useState("");
  const [file2, setFile2] = useState("");
  const [b64File3, setB64File3] = useState("");
  const [file3, setFile3] = useState("");
  const [b64File4, setB64File4] = useState("");
  const [file4, setFile4] = useState("")
  const [b64File5, setB64File5] = useState("");
  const [file5, setFile5] = useState("")
  const [b64File6, setB64File6] = useState("");
  const [file6, setFile6] = useState("")
  const [b64File7, setB64File7] = useState("");
  const [file7, setFile7] = useState("")


  const [buttonLoader, setButtonLoader] = useState(false);
  const [filetype1, setFiletype1] = useState("");
  const [filetype2, setFiletype2] = useState("");
  const [filetype3, setFiletype3] = useState("");
  const [filetype4, setFiletype4] = useState("");
  const [filetype5, setFiletype5] = useState("");
  const [filetype6, setFiletype6] = useState("");
  const [filetype7, setFiletype7] = useState("");
  const [nextflag, setNextflag] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible,setModalvisible] =useState(false)
  const [timer, setTimer] = useState(10);
  const [reportUrl, setReportUrl] = useState("");
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const search_query = new URLSearchParams(search).get("source");
  let profileData = localStorage.getItem("patientInfo");
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
  console.log(storedData?.data, "ProfileDataaa");
  const {caresathi}  = useApiData();



  const ContactlessMonitoring = caresathi?.attributes?.forthComp?.[1]?.image_url?.[9]?.image_url;

  const RealTimeAccuracy = caresathi?.attributes?.forthComp?.[1]?.image_url?.[13]?.image_url;
  ;

  const EmpatheticInsights = caresathi?.attributes?.forthComp?.[1]?.image_url?.[12]?.image_url;
  ;
  const PeaceOfMind = caresathi?.attributes?.forthComp?.[1]?.image_url?.[11]?.image_url;
  const AIDrivenAccuracy = caresathi?.attributes?.forthComp?.[1]?.image_url?.[10]?.image_url;

  const aitool = caresathi?.attributes?.thirdComp?.[0]?.image_url;
  const labinsight = caresathi?.attributes?.thirdComp?.[2]?.image_url;
  const caresathim = caresathi?.attributes?.headerComp?.[0]?.image_url;
  const SmartVitals = caresathi?.attributes?.thirdComp?.[1]?.image_url;

  const changeHandler1 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    setFiletype1(fileType);
    const validFileTypes = ["JPG", "PNG", "JPEG"];

    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File1(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File1(""); // Clear the previous image if error occurs
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile1(base64File);
    setB64File1(base64File.split(",")[1]);

    setError(null);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const changeHandler2 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    setFiletype2(fileType);
    const validFileTypes = ["JPG", "PNG", "JPEG"];

    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File2(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File2(""); // Clear the previous image if error occurs
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile2(base64File);
    setB64File2(base64File.split(",")[1]);

    setError(null);
  };

  const changeHandler3 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    const validFileTypes = ["JPG", "PNG", "JPEG"];
    setFiletype3(fileType);
    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File3(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File3(""); // Clear the previous image if error occurs
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile3(base64File);
    setB64File3(base64File.split(",")[1]);

    setError(null);
  };

  const changeHandler4 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    const validFileTypes = ["JPG", "PNG", "JPEG"];
    setFiletype4(fileType);
    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File4(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File4("");
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile4(base64File);
    setB64File4(base64File.split(",")[1]);

    setError(null);
  };

  const changeHandler5 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    const validFileTypes = ["JPG", "PNG", "JPEG"];
    setFiletype5(fileType);
    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File5("") // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File5("");
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile5(base64File);
    setB64File5(base64File.split(",")[1]);

    setError(null);
  };

  const changeHandler6 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    const validFileTypes = ["JPG", "PNG", "JPEG"];
    setFiletype4(fileType);
    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File6(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File6("");
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile6(base64File);
    setB64File6(base64File.split(",")[1]);

    setError(null);
  };

  const changeHandler7 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    const validFileTypes = ["JPG", "PNG", "JPEG"];
    setFiletype7(fileType);
    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File7(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File7("");
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile7(base64File);
    setB64File7(base64File.split(",")[1]);

    setError(null);
  };

  console.log(b64File4, b64File5, b64File6, b64File7, "fgvhbjn")

  const clicktype1 = () => {
    hiddenFileInput1.current.click();
  };

  const clicktype2 = () => {
    hiddenFileInput2.current.click();
  };
  const clicktype3 = () => {
    hiddenFileInput3.current.click();
  };
  const clicktype4 = () => {
    hiddenFileInput4.current.click();
  };

  const clicktype5 = () => {
    hiddenFileInput5.current.click();
  };

  const clicktype6 = () => {
    hiddenFileInput6.current.click();
  };

  const clicktype7 = () => {
    hiddenFileInput7.current.click();
  };

  console.log(process.env.REACT_APP_NEWBASEURL, process.env, "environ");
  console.log(userData, "UserData");

  const startTimer = () => {
    let countdown = 15;
    const interval = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleFormSubmit = async () => {
 
    
    try {
      // Assuming userData, b64File1, b64File2, b64File3 validations are already done
     
      if (![b64File1, b64File2, b64File3, b64File4, b64File5, b64File6, b64File7].some(file => file)) {
        toast("Please upload at least one image before submitting.");
        return;
      }
     
      setButtonLoader(true);

      const payload = {
        patient_id: userData.code,
        front_view: b64File1,
        upper_view: b64File2,
        lower_view: b64File3,
        oral_image_1:b64File4,
        oral_image_2:b64File5,
        oral_image_3:b64File6,
        oral_image_4:b64File7,
        gender: storedData?.data?.gender,
        mobile_number: storedData?.data?.mobile,
        date: getFormattedDate(),
        age: calculateAge(storedData?.data?.dob),
        name: `${storedData?.data?.firstName} ${storedData?.data?.lastName}`,
      };

      toast("Please wait while we generate your Dental Report");

      // First API call
      const response = await fetch(
        `${process.env.REACT_APP_DENTALCARE}process-image/`,
       
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      setButtonLoader(false);

      if (response.status === 200) {
        const responseData = await response.json();
        startTimer();
        console.log(responseData);
  
        setIsModalVisible(true);
        setReportUrl(responseData?.url);
        startTimer();

        setB64File1("");
      setB64File2("");
      setB64File3("");
      setB64File4("");
      setB64File5("");
      setB64File6("");
      setB64File7("");

      setFile1("");
      setFile2("");
      setFile3("");
      setFile4("");
      setFile5("");
      setFile6("");
      setFile7("");

      setModalvisible(false);
        
        // Second API call
        // const reportResponse = await fetch(
        //   `${process.env.REACT_APP_NEWBASEURL}dentalReport/generate`,
        
        //   {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify(payload),
        //   }
        // );

        // if (reportResponse.status === 200) {
        //   const reportData = await reportResponse.json();
        //   console.log(reportData, "Report Response");

        

        
        // } else {
        //   toast("Report generation failed. Please try again.");
        // }
      } else {
        toast("There was an issue with your submission. Please try again.");
      }
    } catch (e) {
      console.error(e);
      toast("An error occurred. Please try again.");
      setButtonLoader(false);
    }
  };

  const getFormattedDate = () => {
    const now = new Date();
    return now.toISOString(); // Generates date in the 'YYYY-MM-DDTHH:mm:ss.SSSZ' format
  };

  if (search_query == "qrcode" && !checkIsLoggedIn()) {
    history.push({
      pathname: "/",
      state: { from: location.pathname, isLoginModalOpen: true },
    });
    return;
  }

  const handleNext = (e) => {
    if (!userData.code) {
      e.preventDefault(); // Prevent navigation
      history.push({
        pathname: APP_ROUTES.HOME,
        state: { isLoginModalOpen: true },
      });
    }
    else {
      setModalvisible(true)
    }


  }

  const handleBack = () => {
    setNextflag(false); // Goes back to the previous UI state
  };

  console.log(b64File7,b64File6,b64File5,b64File4,b64File3,b64File1,b64File2, "dffggyyg")


  return (
    <>
    <Layouts>
      <SubHeader />
      <ToastContainer />
     
      <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <span className="text-secondaryColor text-sm md:text-6xl font-medium  mb-5 md:mb-3">
                Oral Care
              </span>
              <h1 className="text-primaryColor md:text-White text-2xl md:text-3xl font-bold sm:mt-8 mt-0 ">
                AI-Power Insights for a
              </h1>
              <span className="text-secondaryColor text-sm md:text-3xl font-medium  mb-1 md:mb-3">

                Healthier Smile
              </span>

              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2 mb-4">
              Your oral health is more than just your teeth—it’s your smile, confidence, and overall well-being. OralCare by CureBay uses advanced AI technology to detect 14+ oral conditions from just a few pictures, providing instant insights and personalized recommendations. AI-powered recommendations help you understand the cause, risk factors, and next steps for better oral care.
              </p>
             
            
            </div>
            {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img
                  src={dentalcareimg1}
                  alt="images"
                  className="w-full h-full  rounded-xl"
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="">
  <div className="container mx-auto py-[50px] md:py-[90px]">
    <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
      AI-Powered Oral Health <span className="text-secondaryColor">Ecosystem</span>
    </h2>

    <div className="flex flex-col md:flex-row items-center justify-center mt-4 sm:mt-10">
      {/* Image Section */}
      <div className="w-full md:w-1/2 flex justify-center">
        <img src={dentalcareimg2nd} className="max-w-full h-auto" alt="Dental Care" />
      </div>

      {/* Text Content Section */}
      <div className="w-full md:w-1/2   ">
        <h2 className=" text-lg sm:text-3xl font-bold text-primaryColor">Know your Oral Health for Free</h2>
        <p className="text-[#676C80] mt-2 text-base sm:text-2xl">
          Transforming dental health with intelligent diagnostics, personalized insights, and proactive prevention.
          From teeth, gums, tongue, to inner cheeks, let AI guide your journey to a brighter, healthier smile!
        </p>
        <button className="mt-4 px-4 py-2 bg-primaryColor text-White rounded-md" onClick={(e)=>handleNext(e)}>
          Upload Image Now
        </button>
      </div>
    </div>
  </div>
</section>


     <section className="">
        <div className="container mx-auto py[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
            How it <span className="text-secondaryColor">Works</span>?
          </h2>
          <div className="px-[14px] pb-6 sm:pb-auto sm:px-auto md:w-[80%] mx-auto flex sm:flex-row flex-col justify-between items-start gap-10">
            {/* Step 1 */}
            <div className="gap-3 sm:gap-auto flex sm:flex-col flex-row text-left">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW1} alt="Step 1" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold text-left"
                  style={{ color: "#42BA85" }}
                >
                  STEP 1
                </div>
                <div
                  className="text-base font-semibold text-left"
                  style={{ color: "#004171" }}
                >
                 Take Picture
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
            Take clear pictures of your teeth, gums, and mouth using the app

                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full sm:w-20 w-24 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW2} alt="Step 2" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 2
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                 AI Scan
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                AI scans and detects oral health conditions.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW3} alt="Step 3" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 3
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Get Instant Results
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                Instantly receive a detailed health report with a Dental & Oral Score
                </p>
              </div>
            </div>

            {/* Step 4 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row ">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW4} alt="Step 4" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 4
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Get Recommendations
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                   Get AI-driven personalised recommendations for better oral hygiene
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>




<section className="bg-[#f3f8ff]">
        <div className="container mx-auto py[50px] md:py-[90px] px-4">
          <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
            What You Can <span className="text-secondaryColor">Expect</span>
          </h2>
          <div className="flex justify-center items-center min-h-screen">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-0">
              <div className="flex flex-col items-center">
                <img src={ContactlessMonitoring} alt="Touch-Free Monitoring" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                   Early Detection, Smarter Care
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    14+ oral conditions detected with AI driven accuracy ensuring timely intervention
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={RealTimeAccuracy} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Comprehensive Oral Health Check
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                  Get a full assessment of your dental and oral health, analysed for key concerns
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={PeaceOfMind} alt="Convenient Setup" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                   Detailed Report, Clear Insights
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                  Receive a PDF report with an easy-to-understand table of findings for better clarity
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={EmpatheticInsights} alt="Peace Of Mind" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                   Oral Hygiene, Tailored for You
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                   Get customised tips and actionable next steps for maintaining optimal oral hygiene
                  </p>
                </div>
              </div>

            
              <div className="flex flex-col items-center sm:col-span-2 justify-self-center text-center ">
                <img src={AIDrivenAccuracy} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                   Convenient, No Dentist Visit Required
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                   Skip the clinic for your initial assessment- get a dental health check anytime, anywhere !
                  </p>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightGray mb-4">
        <div className="bg-[#023b72] py-12 flex flex-col justify-center items-center min-h-screen">
          <div className=" block sm:hidden text-center  z-10">
            <h2 className="text-3xl font-bold mb-2 text-White">
              Check Our Other
            </h2>
            <h3 className="text-3xl font-bold text-[#42BA85] mb-6">AI Tools</h3>
          </div>
          {/* Main Container */}

          <div className=" w-full max-w-5xl rounded-2xl flex flex-col  items-start justify-between relative overflow-hidden">
            {/* Left Section with Heading */}
            <div className="hidden sm:flex w-full  max-w-5xl bg-[#0a5a9c]  rounded-t-2xl  items-start justify-between relative overflow-hidden">
              <div className="text-white z-10 md:w-1/2 sm:pl-[50px] sm:pt-[100px]">
                <h2 className="text-3xl font-bold mb-2 text-White">
                  Check Our Other
                </h2>
                <h3 className="text-3xl font-bold text-[#42BA85] mb-6">
                  AI Tools
                </h3>
              </div>

              {/* Background Image */}
              <img
                src={aitool}
                alt="Doctor"
                className="  md:w-[66%] w-full object-cover rounded-t-2xl"
                style={{ zIndex: 1, maxHeight: "100%" }}
              />
            </div>

            {/* AI Tools Cards Section */}
            <div className=" rounded-b-xl bg-[#0a5a9c] grid grid-cols-1 md:grid-cols-3 gap-6 z-10 mt-8 md:mt-0 px-[50px] py-[45px]">
              {/* Card 1 */}
              <div className="bg-white rounded-xl overflow-hidden ">
                <img
                  src={labinsight}
                  alt="Labinsight"
                  className="w-full object-cover"
                />
                <div
                  className="p-4 bg-White rounded-b-xl "
                  style={{ height: "132px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    LabInsights
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    From uncertainty to clarity, we are with you
                  </p>
                  <a
                    href="/lab-insight"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={caresathim}
                  alt="Smart Vitals"
                  className="w-full  object-cover"
                />
                <div className="p-4  bg-White rounded-b-xl ">
                  <h4 className="text-lg font-semibold text-[#004171]">
                    CareSathi
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Get insights from your lab reports with AI precision
                  </p>
                  <a
                    href="/care-sathi"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 3 */}

         

              <div className="bg-white rounded-xl overflow-hidden ">
                <img
                  src={SmartVitals}
                  alt="Labinsight"
                  className="w-full object-cover"
                />
                <div
                  className="p-4 bg-White rounded-b-xl "
                  style={{ height: "132px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    SmartVitals
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Blood pressure, pulse rate, heart rate without touch
                  </p>
                  <a
                    href="/smart-vitals-detail"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dialog
        visible={modalVisible}
        footer={null}
       
        onHide={() => {if (!modalVisible) return; setModalvisible(false); }}
        centered
        style={{ zIndex: 1000 }}

      >
  
  <div className="">
  

  
    <div className="mt-10 bg-white p-6 md:p-10 ">
      <h2 className="text-xl md:text-2xl font-bold text-primaryColor">
        How to capture <span className="text-secondaryColor">clear images</span>?
      </h2>
      <ul className="list-disc mt-4 text-gray-700 space-y-2 pl-5">
        <li><strong>Use natural lighting</strong> to avoid shadows and glare</li>
        <li>Hold the camera steady and ensure the image is <strong>clear and focused</strong></li>
        <li>Slightly tilt your head for better visibility of upper and lower teeth</li>
        <li>Gently pull your cheek or lip outward to expose the area</li>
        <li>Stick out your <strong>tongue fully</strong> to capture both the top and underside</li>
        <li>Avoid <strong>filters or image alterations</strong> for accurate AI analysis</li>
      </ul>
      
      {/* Disclaimer Section */}
   
    </div>


    {/* <div className=" mx-auto ">

    <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
    Upload photos and get an instant  <span className="text-secondaryColor">oral health report </span>{" "}
            for free
          </h2>
         
         

              <div className="md:w-[70%] mx-auto grid md:grid-cols-4 gap-5">

                <div className="rounded-xl ">
                  <div className="mb-5">
                    {file4 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file4}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full ml-4 sm:ml-auto rounded-xl"
                        src={dentalcareimg5}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Gums
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your upper or lower gums, please take a close-up picture of your gums like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype4();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput4}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler4}
                      className="hidden"
                    />
                  </div>
                </div>

                <div className="rounded-xl ">
                  <div className="mb-5">

                    {file5 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file5}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                        src={dentalcareimg6}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Inner cheeks
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your left or right inner cheeks, please take a close-up picture of your inner cheeks like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype5();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput5}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler5}
                      className="hidden"
                    />
                  </div>
                </div>

                <div className=" rounded-xl ">
                  <div className="mb-5">
                    {file6 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file6}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                        src={dentalcareimg7}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Tongue
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your sides, upper, or lower tongue, please take a close-up picture of your tongue like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype6();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput6}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler6}
                      className="hidden"
                    />
                  </div>
                </div>

                <div className="rounded-xl ">
                  <div className="mb-5">
                    {file7 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file7}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                        src={dentalcareimg8}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Inner Lips
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your upper or lower lips, please take a close-up picture of your  lips like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype7();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput7}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler7}
                      className="hidden"
                    />
                  </div>
                </div>


              </div>

              
              <div className="md:w-[70%] mx-auto grid md:grid-cols-3 gap-5">
                <div className="rounded-xl ">
                  <div className="mb-5">
                 
                    {file1 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file1}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                        src={dentalcareimg2}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Front teeth
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your upper and lower
                      front teeth (incisors and canines), please take a close-up
                      picture of your teeth like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype1();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput1}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler1}
                      className="hidden"
                    />
                  </div>
                </div>
                <div className=" rounded-xl ">
                  <div className="mb-5">
                    {file2 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file2}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                        src={dentalcareimg3}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Upper teeth
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your upper teeth
                      (molars and premolars), please take a close-up picture of your
                      upper teeth like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype2();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput2}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler2}
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="rounded-xl ">
                  <div className="mb-5">
                    {file3 ? (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                        src={file3}
                      />
                    ) : (
                      <img
                        alt="images"
                        className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                        src={dentalcareimg4}
                      />
                    )}
                  </div>
                  <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                    <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                      Lower teeth
                    </h5>
                    <p className="text-textGray sm:text-base text-sm">
                      To help us understand the condition of your lower teeth
                      (molars and premolars), please take a close-up picture of your
                      lower teeth like the one on the top.
                    </p>
                    <button
                      onClick={() => {
                        clicktype3();
                      }}
                      className="text-[#067ad0]"
                    >
                      Upload Image
                      <i
                        className="fa fa-chevron-right text-[12px] pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      ref={hiddenFileInput3}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler3}
                      className="hidden"
                    />
                  </div>
                </div>


              </div>
          

       
              <div className="text-center flex justify-center items-center gap-4">
            
              
                <button
                  onClick={handleFormSubmit}
                  disabled={buttonLoader}
                  className="w-full sm:w-auto sm:mt-10 mt-2 bg-primaryColor text-White border-primaryColor border md:border-0 text-xs md:text-base font-semibold py-[15px] px-3 md:px-[60px] rounded-full"
                >
                  {buttonLoader ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{ color: "white" }}
                          color="white"
                        />
                      }
                    />
                  ) : (
                    "Get AI report"
                  )}
                </button>
              </div>

          


        </div> */}

<div className="mx-auto max-w-[90%] md:max-w-[70%]">
  <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-6">
    Upload photos and get an instant <span className="text-secondaryColor">oral health report</span>
  </h2>

  {/* Dental Care Section */}
  <h3 className="text-center text-lg md:text-2xl font-semibold mt-6 mb-4">Dental Care</h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 place-items-center">
    {[{
      title: "Front teeth",
      desc: "Incisors & Canines",
      file: file1,
      img: dentalcareimg2,
      click: clicktype1,
      hiddenRef: hiddenFileInput1,
      change: changeHandler1
    }, {
      title: "Upper teeth",
      desc: "Molars & Premolars",
      file: file2,
      img: dentalcareimg3,
      click: clicktype2,
      hiddenRef: hiddenFileInput2,
      change: changeHandler2
    }, {
      title: "Lower teeth",
      desc: "Molars & Premolars",
      file: file3,
      img: dentalcareimg4,
      click: clicktype3,
      hiddenRef: hiddenFileInput3,
      change: changeHandler3
    }, ].map((item, index) => (
      <div key={index} className="flex flex-col items-center text-center w-[270px]">
        <img className="w-[256px] h-[256px] object-cover rounded-xl" src={item.file || item.img} alt={item.title} />
        <h5 className="text-[#373737] text-lg font-semibold mt-2">{item.title}</h5>
        <p className="text-textGray text-sm mb-2">{item.desc}</p>
        <button className="text-[#067ad0]" onClick={item.click}>Upload photo</button>
        <input ref={item.hiddenRef} type="file" accept="image/jpeg, image/png" onChange={item.change} className="hidden" />
      </div>
    ))}
  </div>

  {/* Oral Care Section */}
  <h3 className="text-center text-lg md:text-2xl font-semibold mt-10 mb-4">Oral Care</h3>
  <div className="grid grid-cols-1 md:grid-cols-4 gap-6 place-items-center">
    {[{
      title: "Gums",
      desc: "Upper or Lower",
      file: file4,
      img: dentalcareimg5,
      click: clicktype4,
      hiddenRef: hiddenFileInput4,
      change: changeHandler4
    },
      {
      title: "Inner cheeks",
      desc: "Left or right side",
      file: file5,
      img: dentalcareimg6,
      click: clicktype5,
      hiddenRef: hiddenFileInput5,
      change: changeHandler5
    }, {
      title: "Tongue",
      desc: "Top, bottom or side",
      file: file6,
      img: dentalcareimg7,
      click: clicktype6,
      hiddenRef: hiddenFileInput6,
      change: changeHandler6
    }, {
      title: "Inner lips",
      desc: "Upper or lower lip inside",
      file: file7,
      img: dentalcareimg8,
      click: clicktype7,
      hiddenRef: hiddenFileInput7,
      change: changeHandler7
    }].map((item, index) => (
      <div key={index} className="flex flex-col items-center text-center w-[270px]">
        <img className="w-[256px] h-[256px] object-cover rounded-xl" src={item.file || item.img} alt={item.title} />
        <h5 className="text-[#373737] text-lg font-semibold mt-2">{item.title}</h5>
        <p className="text-textGray text-sm mb-2">{item.desc}</p>
        <button className="text-[#067ad0]" onClick={item.click}>Upload photo</button>
        <input ref={item.hiddenRef} type="file" accept="image/jpeg, image/png" onChange={item.change} className="hidden" />
      </div>
    ))}
  </div>

  {/* Get AI Report Button */}
  <div className="text-center mt-10">
    <button onClick={handleFormSubmit} disabled={buttonLoader} className="bg-primaryColor text-White font-semibold py-3 px-6 rounded-full">
      {buttonLoader ? <Spin indicator={<LoadingOutlined spin style={{ color: 'white' }} />} /> : "Get AI report"}
    </button>
  </div>

  <div className="mt-4 text-sm text-gray-500">
        <p className="flex items-center">
          <span className="text-[#FFA53A] text-lg mr-1">⚠</span>
          <strong>Disclaimer & Privacy Protection</strong>
        </p>
        <p className="mt-1">
          This report is <strong>AI-generated</strong> and does not replace a
          <strong> professional dental consultation</strong>. Please visit a dentist
          for a detailed <strong>clinical evaluation</strong>. Your images are securely
          processed and will not be <strong>shared or stored</strong> beyond the analysis.
        </p>
      </div>
</div>








  </div>



      </Dialog>
    </Layouts>

    <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        centered
        zIndex={2000}
      >
        <div className="flex flex-col items-center bg-White p-6 ">
          <img src={sucessvectoricon} alt="Success Icon" className="h-24 w-24 mb-4" />

          <h5 className="text-2xl font-bold mt-2 mb-4 text-center text-green-600">
            Your dental report will be delivered to your WhatsApp shortly.
          </h5>

          <p className="text-lg text-center text-textGray mb-6">
            If you don’t receive it within
            <span className="font-semibold text-danger ml-1">
              {timer > 0 ? `${timer} seconds` : "0 seconds"}
            </span>
            , you can download the report below.
          </p>

          {timer === 0 && (
            <a
              href={reportUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
            >
              Download Report
            </a>
          )}
        </div>

      </Modal>
</>

  );
}

export default Dentalcare;

// import React, { useState, useEffect, useRef } from "react";
// import Layouts from "../../Layouts";
// import PersonalizedAssistant from "../../../Assets/Images/Icons/PersonalizedAssistant.svg";
// import EmotionalSupport from "../../../Assets/Images/Icons/EmotionalSupport.svg";
// import Continuous from "../../../Assets/Images/Icons/Continuous.svg";
// import Comperhensive from "../../../Assets/Images/Icons/Comperhensive.svg";
// import PieceMind from "../../../Assets/Images/Icons/PieceMind.svg";
// import SubHeader from "../../Shared/SearchBar";
// import dentalcareimg1 from "../../../Assets/Images/dentalcareimg1.png";
// import dentalcareimg2 from "../../../Assets/Images/dentalcareimg2.png";
// import dentalcareimg3 from "../../../Assets/Images/dentalcareimg3.png";
// import dentalcareimg4 from "../../../Assets/Images/dentalcareimg4.png";
// import { Collapse } from "antd";
// import Arrowimage from "../../../Assets/Images/Icons/linearrow.png";
// import http from "../../../Redux/Services/http-common";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import { checkIsLoggedIn } from "../../../Redux/Action/userActions";
// import { LoadingOutlined } from '@ant-design/icons';
// import {Spin } from 'antd';
// const { Panel } = Collapse;

// const faqData = {
//   faq: [
//     {
//       question: "Crowding of teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Open bite",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Protruded teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Cross bite",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Under bite",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Missing teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Deep bite teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//   ],
// };

// const CustomPanelHeader = ({ headerText }) => {
//   return (
//     <div className="flex gap-4 items-center">
//       {/* <img src={Arrowimage} alt="images" className="h-[20px]" /> */}
//       <span className="text-[#373737] sm:text-2xl text-lg font-medium">
//         {headerText}
//       </span>
//     </div>
//   );
// };
// const encodeBase64File = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// };

// function Dentalcare() {
//   const hiddenFileInput1 = useRef(null);
//   const hiddenFileInput2 = useRef(null);
//   const hiddenFileInput3 = useRef(null);
//   const userData = useSelector((state) => state.authReducer.patientData);
//   console.log(userData, "iuhklnlk");
//   const [error, setError] = useState(null);
//   const [b64File1, setB64File1] = useState("");
//   const [file1, setFile1] = useState("");
//   const [b64File2, setB64File2] = useState("");
//   const [file2, setFile2] = useState("");
//   const [b64File3, setB64File3] = useState("");
//   const [file3, setFile3] = useState("");
//   const [buttonLoader, setButtonLoader] = useState(false)
//   const [filetype1, setFiletype1] = useState("");
//   const [filetype2, setFiletype2] = useState("");
//   const [filetype3, setFiletype3] = useState("");
//   const history = useHistory();
//   const location = useLocation();
//   const search = useLocation().search;
//   const search_query = new URLSearchParams(search).get("source");
//   const changeHandler1 = async (e) => {
//     const file = e.target.files[0]; // Only allow one file
//     if (!file) return;

//     const fileType = file.type.split("/")[1].toUpperCase();
//     setFiletype1(fileType);
//     const validFileTypes = ["JPG", "PNG", "JPEG"];

//     if (!validFileTypes.includes(fileType)) {
//       setError("Please upload JPEG, JPG, PNG formats only");
//       setB64File1(""); // Clear the previous image if error occurs
//       return;
//     }

//     const fileSizeMB = file.size / 1000000;
//     if (fileSizeMB > 5) {
//       setError("Please upload a photo smaller than 5MB");
//       setB64File1(""); // Clear the previous image if error occurs
//       return;
//     }

//     const base64File = await encodeBase64File(file);
//     setFile1(base64File);
//     setB64File1(base64File.split(",")[1]);

//     setError(null);
//   };

//   const changeHandler2 = async (e) => {
//     const file = e.target.files[0]; // Only allow one file
//     if (!file) return;

//     const fileType = file.type.split("/")[1].toUpperCase();
//     setFiletype2(fileType);
//     const validFileTypes = ["JPG", "PNG", "JPEG"];

//     if (!validFileTypes.includes(fileType)) {
//       setError("Please upload JPEG, JPG, PNG formats only");
//       setB64File2(""); // Clear the previous image if error occurs
//       return;
//     }

//     const fileSizeMB = file.size / 1000000;
//     if (fileSizeMB > 5) {
//       setError("Please upload a photo smaller than 5MB");
//       setB64File2(""); // Clear the previous image if error occurs
//       return;
//     }

//     const base64File = await encodeBase64File(file);
//     setFile2(base64File);
//     setB64File2(base64File.split(",")[1]);

//     setError(null);
//   };

//   const changeHandler3 = async (e) => {
//     const file = e.target.files[0]; // Only allow one file
//     if (!file) return;

//     const fileType = file.type.split("/")[1].toUpperCase();
//     const validFileTypes = ["JPG", "PNG", "JPEG"];
//     setFiletype3(fileType);
//     if (!validFileTypes.includes(fileType)) {
//       setError("Please upload JPEG, JPG, PNG formats only");
//       setB64File3(""); // Clear the previous image if error occurs
//       return;
//     }

//     const fileSizeMB = file.size / 1000000;
//     if (fileSizeMB > 5) {
//       setError("Please upload a photo smaller than 5MB");
//       setB64File3(""); // Clear the previous image if error occurs
//       return;
//     }

//     const base64File = await encodeBase64File(file);
//     setFile3(base64File);
//     setB64File3(base64File.split(",")[1]);

//     setError(null);
//   };

//   const clicktype1 = () => {
//     hiddenFileInput1.current.click();
//   };

//   const clicktype2 = () => {
//     hiddenFileInput2.current.click();
//   };
//   const clicktype3 = () => {
//     hiddenFileInput3.current.click();
//   };

//   console.log(process.env.REACT_APP_NEWBASEURL,process.env,"environ")

//   const handleFormSubmit = async () => {
//     try{
// const modified = process.env.REACT_APP_NEWBASEURL === 'https://common-stage.curebay.in/cs/' ? 'XDLFMQXG290724' : process.env.REACT_APP_NEWBASEURL === 'https://common.curebay.com/cs/' ? 'BZGTWCGK290724' : '';

//     if (Object.keys(userData).length === 0) {
//       history.push({pathname: "/", state: {from: location.pathname}});
//     } else {
//       if (
//         b64File1.length === 0 ||
//         b64File2.length === 0 ||
//         b64File3.length === 0
//       ) {
//         // alert('All files must be provided.');
//         toast("All files must be provided.");
//         return false; // Prevent form submission
//       }
//       setButtonLoader(true)
//       const payload = {
//         labTestCode: "EL52R",
//         front_Teeth: b64File1,
//         front_TeethType: filetype1,
//         upper_Teeth: b64File2,
//         upper_TeethType: filetype2,
//         lower_Teeth: b64File3,
//         lower_TeethType: filetype3,
//         orderId: null,
//         patientId: userData.code,
//         patientName: `${userData.firstName} ${userData.lastName}`,
//         modifiedBy: modified,
//         createdBy: modified,
//         eclinicName1: null,
//         eclinicName2: null,
//         eclinicName3: null,
//         eclinicAddress1: null,
//         eclinicAddress2: null,
//         eclinicAddress3: null,
//         eclinicImgLink1: null,
//         eclinicImgLink2: null,
//         eclinicImgLink3: null,
//         status: 1,
//         // sourceChannel:"B2C"
//       };
//       console.log(payload, "payload");

//       const Response = await http.post(
//        `${process.env.REACT_APP_NEWBASEURL}PatientLabTestImageUpload/addPatientLabTestImageUpload`,
//         // `https://common.curebay.com/cs/PatientLabTestImageUpload/addPatientLabTestImageUpload`,
//         payload
//       );
//       console.log(Response, "Response");
//       // {"Status":"Created Successfully","ReportId":"6afa156b-9649-43a4-901d-33bb48919c86","ReportStatus":"processing"}
//       setButtonLoader(false)
//       if (Response.status == 200) {
//       setButtonLoader(false)
//         toast(
//           "Thank you for uploading. You will receive the dental health report shortly."
//         );
//       } else {
//       setButtonLoader(false)
//       }
//     }
//   } catch (e) {
//     setButtonLoader(false)
//   }
//   };
//   if(search_query == "qrcode" && !checkIsLoggedIn()){
//     history.push({pathname: "/", state: {from: location.pathname ,isLoginModalOpen: true }});
//     return;
//   }
//   return (
//     <Layouts>
//       <SubHeader />
//       <ToastContainer />
//       <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
//         <div className="container mx-auto">
//           <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
//           <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
//             <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
//               <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
//                 Straighten your smile with{" "}
//                 <span className="text-secondaryColor">CureBay's aligners </span>{" "}
//                 or braces.
//               </h1>
//               <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
//                 CureBay offers clear aligners for a comfortable, discreet way to
//                 straighten your teeth and boost your confidence. Get started on
//                 your perfect smile today!
//               </p>
//               {/* <button
//                 // onClick={() => window.open("https://curebaytotalcare.com/")}
//                 className="mt-5 bg-White text-primaryColor border-primaryColor border md:border-0 text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full"
//               >
//                 Know your oral health
//               </button> */}
//             </div>
//             {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
//               <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
//                 <img
//                   src={dentalcareimg1}
//                   alt="images"
//                   className="w-full h-full  rounded-xl"
//                 />
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </section>
//       <section className="sm:bg-lightGray">
//         <div className="container mx-auto py-[50px] md:py-[90px]">
//           <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
//             Know Your <span className="text-secondaryColor">Oral Health </span>{" "}
//             for free
//           </h2>
//           <p className=" text-center sm:text-2xl text-lg font-normal text-[#676C80] sm:mb-[40px] mb-[20px]">
//             Upload photos & get an instant oral health report
//           </p>
//           <div className="md:w-[70%] mx-auto grid md:grid-cols-3 gap-5">
//             <div className="rounded-xl ">
//               <div className="mb-5">
//                 {/* {fileDataURL?.length ? (
//                   <> */}
//                 {file1 ? (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
//                     src={file1}
//                   />
//                 ) : (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto"
//                     src={dentalcareimg2}
//                   />
//                 )}
//               </div>
//               <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
//                 <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
//                   Front teeth
//                 </h5>
//                 <p className="text-textGray sm:text-base text-sm">
//                   To help us understand the condition of your lower front teeth
//                   (canine) and back teeth (molars), please take a close-up
//                   picture of your lower teeth like the one on the top.
//                 </p>
//                 <button
//                   onClick={() => {
//                     clicktype1();
//                   }}
//                   className="text-[#067ad0]"
//                 >
//                   Upload Image
//                   <i
//                     className="fa fa-chevron-right text-[12px] pl-2"
//                     aria-hidden="true"
//                   ></i>
//                 </button>
//                 <input
//                   ref={hiddenFileInput1}
//                   type="file"
//                   accept="image/jpeg, image/png"
//                   onChange={changeHandler1}
//                   className="hidden"
//                 />
//               </div>
//             </div>
//             <div className=" rounded-xl ">
//               <div className="mb-5">
//                 {file2 ? (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
//                     src={file2}
//                   />
//                 ) : (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto"
//                     src={dentalcareimg3}
//                   />
//                 )}
//               </div>
//               <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
//                 <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
//                   Upper teeth
//                 </h5>
//                 <p className="text-textGray sm:text-base text-sm">
//                   To help us understand the condition of your lower front teeth
//                   (canine) and back teeth (molars), please take a close-up
//                   picture of your lower teeth like the one on the top.
//                 </p>
//                 <button
//                   onClick={() => {
//                     clicktype2();
//                   }}
//                   className="text-[#067ad0]"
//                 >
//                   Upload Image
//                   <i
//                     className="fa fa-chevron-right text-[12px] pl-2"
//                     aria-hidden="true"
//                   ></i>
//                 </button>
//                 <input
//                   ref={hiddenFileInput2}
//                   type="file"
//                   accept="image/jpeg, image/png"
//                   onChange={changeHandler2}
//                   className="hidden"
//                 />
//               </div>
//             </div>
//             <div className="rounded-xl ">
//               <div className="mb-5">
//                 {file3 ? (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
//                     src={file3}
//                   />
//                 ) : (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto"
//                     src={dentalcareimg4}
//                   />
//                 )}
//               </div>
//               <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
//                 <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
//                   Lower teeth
//                 </h5>
//                 <p className="text-textGray sm:text-base text-sm">
//                   To help us understand the condition of your lower front teeth
//                   (canine) and back teeth (molars), please take a close-up
//                   picture of your lower teeth like the one on the top.
//                 </p>
//                 <button
//                   onClick={() => {
//                     clicktype3();
//                   }}
//                   className="text-[#067ad0]"
//                 >
//                   Upload Image
//                   <i
//                     className="fa fa-chevron-right text-[12px] pl-2"
//                     aria-hidden="true"
//                   ></i>
//                 </button>
//                 <input
//                   ref={hiddenFileInput3}
//                   type="file"
//                   accept="image/jpeg, image/png"
//                   onChange={changeHandler3}
//                   className="hidden"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="text-center">
//             <button
//               onClick={handleFormSubmit}
//               disabled = {buttonLoader}
//               className="w-full sm:w-auto sm:mt-10 mt-2 bg-primaryColor text-White border-primaryColor border md:border-0 text-xs md:text-base font-semibold py-[15px] px-3 md:px-[60px] rounded-full"
//             >
//               {buttonLoader ? <Spin indicator={<LoadingOutlined spin style={{color: "white"}} color="white" />} /> : "Get AI report"}
//             </button>
//           </div>
//         </div>
//       </section>

//       <section className="sm:py-[45px] pb-[20px]">
//         <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
//           Teeth alignment issues those require an{" "}
//           <span className="text-secondaryColor">Orthodontic Treatment</span>
//         </h2>
//         <div className="steps-container grid md:w-[90%] mx-auto sm:mt-[50px] mt-[20px] sm:px-[100px] px-[15px] sm:gap-4 gap-1 dentaldiv3">
//           <Collapse
//             accordion
//             className="faqCollapse faqSecond2"
//             expandIconPosition="end"
//           >
//             {faqData?.faq?.map((item, index) => (
//               <Panel header={<CustomPanelHeader headerText={item.question} />}>
//                 <p className="text-[#676C80] font-normal sm:text-lg text-base">
//                   {item.answer}
//                 </p>
//               </Panel>
//             ))}
//           </Collapse>
//         </div>
//       </section>

//       <section className="bg-lightGray py-[45px]">
//         <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
//           Why Choose CureBay for Your{" "}
//           <span className="text-secondaryColor">Orthodontic Treatment? </span>
//         </h2>
//         <div className="steps-container grid md:w-[90%] mx-auto sm:grid-cols-2 grid-cols-1 mt-[50px] sm:px-[100px] px-[15px] sm:gap-4 gap-1">
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 1
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Expert Orthodontists
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Our team consists of highly qualified and experienced
//                 orthodontic specialists.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 2
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Advanced Technology
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Utilization of the latest orthodontic technologies for precise
//                 and efficient treatment.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 3
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Personalized Treatment Plans
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Customized aligners and braces tailored to your unique dental
//                 needs.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 4
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Convenient Process
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Easy photo uploads for instant oral health reports and treatment
//                 progress tracking.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 5
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Comfortable Solutions
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Clear aligners designed for maximum comfort and minimal
//                 visibility.
//               </p>
//             </div>
//           </div>

//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 6
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Comprehensive Care
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Full range of services from initial consultation to
//                 post-treatment follow-up.
//               </p>
//             </div>
//           </div>

//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 7
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Affordable Options
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Competitive pricing with flexible payment plans to suit your
//                 budget.
//               </p>
//             </div>
//           </div>

//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 8
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Positive Results
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Proven track record of successful treatments and satisfied
//                 patients.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Layouts>
//   );
// }

// export default Dentalcare;
