import React, { useRef } from "react";
import Layouts from "../Layouts";
import Swipicon from "../../Assets/Images/Icons/swip.svg";
import Dreamer1 from "../../Assets/Images/Partners/dreamer1.png";
import Dreamer2 from "../../Assets/Images/Partners/dreamer2.png";
import Dreamer3 from "../../Assets/Images/Partners/dreamer3.png";
import Dreamer4 from "../../Assets/Images/Partners/dreamer4.png";
import Dreamer5 from "../../Assets/Images/Partners/dreamer5.png";
import Dreamer6 from "../../Assets/Images/Partners/dreamer6.png";
import Dreamer10 from "../../Assets/Images/Partners/dreamer10.jpg";
import Dreamer8 from "../../Assets/Images/Partners/dreamer8.png";
import Dreamer9 from "../../Assets/Images/Partners/dreamer9.png";
import Director1 from "../../Assets/Images/Partners/director1.png";
import Director2 from "../../Assets/Images/Partners/director2.png";
import Director3 from "../../Assets/Images/Partners/director3.png";
import Advisor1 from "../../Assets/Images/Partners/Dr.BIREN.png";
import SubHeader from "../Shared/SearchBar";
function OurTeam() {
  const scrollTargetRef = useRef(null);

  const scrollToTarget = () => {
    scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Layouts>
      <SubHeader />
      <section className="home-banner md:min-h-[70vh] my-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[70vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[70vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                CureBay Team of{" "}
                <span className="text-secondaryColor">Industry Leaders</span> &
                Experts
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                At CureBay, we are privileged to have a remarkable team that aim
                to revolutionize the healthcare industry with their unwavering
                commitment and unparalleled expertise. Meet the individuals who
                are leading the charge in delivering cutting-edge solutions and
                transforming the way we experience healthcare in India.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%]  rounded ">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/aboutus.png"
                  alt="images"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex justify-center ">
          <img
            className="cursor-pointer"
            src={Swipicon}
            alt="images"
            onClick={scrollToTarget}
          />
        </div>
      </section>
      <section className="bg-White md:pt-[90px]" ref={scrollTargetRef}>
        <h2 className="text-center hidden md:block text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
          Meet the <span className="text-secondaryColor">Dreamers</span>
        </h2>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <h2 className="text-center md:hidden block text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
              Meet the <span className="text-secondaryColor">Dreamers</span>
            </h2>
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img src={Dreamer1} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Priyadarshi Mohapatra
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  FOUNDER & CEO
                </h4>
                <p className="text-textGray md:leading-loose">
                  Priyadarshi boasts an extensive background spanning over 25
                  years, straddling both enterprise and consumer sectors across
                  India. He has excelled in the corporate arena by placing
                  paramount importance on customer engagement. With a strategic
                  mindset, adept execution, and exceptional leadership skills,
                  he has gained respect from clients and peers in companies like
                  Google, Microsoft, SAP, Avaya, and Sun.{" "}
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  {" "}
                  Priyadarshi was a co-founder of Oyzterbay, an early
                  venture-capital-funded jewellery chain that evolved into a
                  prominent lifestyle brand and the second-largest branded
                  jewellery retail chain before its acquisition by Fossil.
                  Commencing his career at Titan Industries, Priyadarshi played
                  an integral role in establishing the Tanishq brand.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full  items-center gap-5">
              <div className="block md:hidden md:w-[40%] mb-10 md:mb-0  flex justify-center">
                <img src={Dreamer2} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Shobhan Mahapatra
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  CO-FOUNDER 
                </h4>
                <p className="text-textGray md:leading-loose">
                  Shobhan possesses over 25 years of professional experience
                  within the manufacturing and IT service sectors. He has
                  overseen extensive IT outsourcing initiatives across various
                  regions, managing diversified businesses with an annual
                  revenue of USD 100 million.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  His expertise spans operations management, strategic business
                  development, organizational restructuring, and adept solution
                  selling. Notably, during his tenure as CIO at Birlasoft, he
                  effectively orchestrated the integration of IT systems,
                  successfully merging Birlasoft's systems with KPIT IT
                  services. Shobhan is an alumnus of NIT Rourkela and XIM
                  Bhubaneswar, with a background that reflects a strong
                  amalgamation of technology and business acumen.
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] mb-10 md:mb-0  flex justify-center">
                <img src={Dreamer2} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0  flex justify-center">
                <img src={Dreamer3} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor md:text-2xl text-xl font-medium mb-3">
                  Sanjay Swain
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  CO-FOUNDER & CTO
                </h4>
                <p className="text-textGray md:leading-loose">
                  Sanjay brings two decades of experience in spearheading
                  technology-driven programs aimed at yielding significant
                  business outcomes for clients and investors globally. His
                  skill set combines a strong blend of technical, functional,
                  and leadership expertise, enabling him to establish and
                  oversee numerous teams that have translated innovative product
                  concepts into tangible solutions.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  His domain of proficiency encompasses product engineering,
                  data management, AI/ML, and the adoption of cloud
                  technologies. Throughout his career in companies such as TCS,
                  CISCO, TIBCO, Nielsen and NielsenIQ, he has demonstrated a
                  successful track record in leading high-performing teams that
                  developed expansive global platforms catering to operations,
                  data science, and client services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full  items-center gap-5">
              <div className="md:hidden block md:w-[40%] mb-10 md:mb-0  flex justify-center">
                <img src={Dreamer4} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Vasant Nangia
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                Strategic Counsel
                </h4>
                <p className="text-textGray md:leading-loose">
                  Vasant Nangia, former CEO of Chumbak Design Private Ltd, held
                  an impactful role as the Senior Vice-President for
                  Asia-Pacific at Fossil Group, overseeing intricate and diverse
                  markets in Japan, Korea, Australia, and South East Asia. His
                  focus lay in fortifying e-commerce, omni-channel, and
                  analytics capabilities. Vasant co-founded Oyzterbay, a
                  pioneering brand in contemporary and affordable jewellery.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  He holds an economics degree from St. Stephens College Delhi
                  and an MBA from IIM Calcutta. He is notably recognized for his
                  integral role in establishing the market dominance of Titan
                  and Tanishq brands in India. Vasant's introduction of the
                  Karat-meter—a device for swift measurement of gold
                  purity—ushered in a pivotal moment for both Tanishq and the
                  Indian jewellery industry.
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] flex justify-center">
                <img src={Dreamer4} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full  items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0  flex justify-center">
                <img src={Dreamer5} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor md:text-2xl text-xl font-medium mb-3">
                  Dr. Shikha Suman
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  HEAD - STRATEGIC PARTNERSHIPS
                </h4>
                <p className="text-textGray md:leading-loose">
                  Dr. Suman is a seasoned entrepreneur known for her adeptness
                  in establishing, scaling, and successfully exiting two
                  businesses. Her comprehensive skill set encompasses product
                  development, team building, and securing financial backing,
                  and she is widely recognized within India's dynamic startup
                  ecosystem.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  With over 17 years of experience, she primarily focuses on the
                  Healthcare and Health Tech sectors, crafting scalable
                  solutions to optimize financial and operational efficiency for
                  healthcare providers and pharmaceutical companies. Her
                  extensive qualifications include a Ph.D. from IIT Kanpur, and
                  she was honored with the Axis Bank Digital Women Award for her
                  influential contributions to these domains.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full  items-center gap-5">
              <div className="md:hidden block md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img src={Dreamer6} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Siddhartha Das
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  HEAD - SALES & OPERATIONS
                </h4>
                <p className="text-textGray md:leading-loose">
                  Siddhartha boasts more than 22 years of experience, a
                  substantial portion of which was in the Durable Industry and
                  Telecom sector. He significantly contributed to the launches
                  of prominent brands like LG, Airtel, and Tata Docomo within
                  the Indian market. Siddhartha served in pivotal roles, leading
                  sales and business units for recognized brands such as Tata
                  Docomo and Reliance.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  He has a fervent passion for fostering an engaging team
                  culture and understands the impact of habits and people in
                  achieving consistent, exceptional outcomes. His strong belief
                  centers on the idea that a well-knit team consistently
                  generates remarkable results.
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] flex justify-center">
                <img src={Dreamer6} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full  items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img
                  src={Dreamer10}
                  alt="images"
                  width="394"
                  className=" rounded-lg"
                />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Himanshu Dhiman
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  HEAD OF DATA MONETIZATION
                </h4>
                <p className="text-textGray md:leading-loose">
                  Himanshu Dhiman brings over 15 years of extensive experience
                  in Commercial, Digital, Operations, Data Analytics, and
                  Product domains to his role as Head of Data Monetization at
                  CureBay. He is pivotal in aligning CureBay's data strategies
                  with its mission to improve healthcare access in underserved
                  regions, driving strategic initiatives that maximize the value
                  of data assets. Throughout his career, Himanshu has made
                  significant contributions to globally recognized organizations
                  such as Fossil Group, Snapdeal, ZS Associates, and Reliance.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  At Fossil Group, he led digital transformation initiatives
                  that enhanced operational efficiencies and customer
                  engagement. His tenure at Snapdeal involved leveraging data
                  analytics to optimize e-commerce platform performance,
                  significantly contributing to the company's growth. At ZS
                  Associates, he delivered strategic insights that improved
                  client business performance, and at Reliance, he managed
                  multiple projects that bolstered the company's growth.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  Himanshu holds a B.Tech (H) and M.Tech from the prestigious
                  Indian Institute of Technology (IIT) Kharagpur, providing him
                  with a robust foundation in technical and analytical skills.
                  Additionally, he has qualified for Level II of the Chartered
                  Financial Analyst (CFA) program, underscoring his commitment
                  to continuous learning and professional development.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:hidden block md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img src={Dreamer8} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Dr Shilpa Bhatte
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  CHIEF PROGRAM OFFICER
                </h4>
                <p className="text-textGray md:leading-loose">
                  Dr. Bhatte holds 16 years of experience in the global
                  for-impact sector, focusing on extending public health
                  interventions to remote communities. Her roles encompassed
                  emergency obstetric care partnerships with the Government of
                  India, FOGSI, and Jhpiego. Transitioning to combat childhood
                  blindness, she led as Country Lead at Vitamin Angels, reaching
                  50 million Indian children with vital micronutrients within a
                  decade.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  Honoured with the 2017 Sight and Life Nutrition Leadership
                  Award, she's recognized for visionary healthcare system
                  transformation. Acknowledged by Medicines Sans Frontiers (MSF)
                  in 2018, Dr. Bhatte's role at Intelehealth involved global
                  telehealth project implementation across India, Kyrgyzstan,
                  and Syria’s hardest-to-reach areas.
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] flex justify-center">
                <img src={Dreamer8} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img src={Dreamer9} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Nikhil Gupta
                </h2>
                <h4 className="text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  HEAD - FINANCE
                </h4>
                <p className="text-textGray md:leading-loose">
                  Nikhil, a proficient finance professional, excels in driving
                  startups to remarkable success. Holding a Chartered Accountant
                  certification from the Institute of Chartered Accountants of
                  India, his career showcases a keen entrepreneurial spirit and
                  financial acumen. As the former Chief of Staff and Finance
                  Head at Uable, a Social Tech startup, Nikhil orchestrated
                  successful funding rounds, securing a notable US$ 8 million in
                  Pre-Series A investments from renowned investors.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  His entrepreneurial endeavours extend to co-founding The
                  Boutique Club, a disruptive travel and hospitality startup.
                  Previously, in CFO roles at Jukaso Group and as Finance
                  Manager at Normet India, Nikhil refined his expertise in
                  financial planning, analysis, risk management, and team
                  leadership, driving growth and profitability for these leading
                  companies.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mt-[50px]">
          Board of <span className="text-secondaryColor">Directors</span>
        </h2>
        <div className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full   items-center gap-5">
              <div className="md:hidden block md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img src={Director1} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Bhaskar Pramanik
                </h2>
                <h4 className="uppercase text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  Chairman & Independent Director
                </h4>
                <p className="text-textGray md:leading-loose">
                  Mr. Bhaskar is an esteemed management leader in the technology
                  sector, having held significant national and global roles in
                  leading multinational tech companies. He contributed
                  significantly, serving on the executive committees of NASSCOM,
                  CII's National Committee, and AMCHAM.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  Recently retiring from the Board of SBI, India's largest bank,
                  he concluded his illustrious 45-year career after chairing
                  Microsoft India. Before this, he served as the Managing
                  Director of Oracle Corporation and Sun Microsystems in India.
                  Bhaskar holds a BTech from IIT Kanpur and an MEP from Stanford
                  University.
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] flex justify-center">
                <img src={Director1} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img src={Director2} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Jyotsana Krishnan
                </h2>
                <h4 className="uppercase text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  Nominee Director- Elevar Equity
                </h4>
                <p className="text-textGray md:leading-loose">
                  Jyotsana, a hardened Managing Partner at Elevar Equity, boasts
                  a remarkable 12-year tenure characterized by her exceptional
                  expertise in the realm of impact investing. Her previous role
                  as Vice President at HSBC saw her at the forefront, leading
                  key initiatives in distribution, wealth management, and
                  strategic business analysis.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  Notably, she's an alumna of both SPJIMR and Sri Ram College of
                  Commerce, a testament to her unwavering commitment to
                  continual learning and dedication to excellence. Through her
                  dynamic leadership and an unyielding commitment to driving
                  positive transformation, she plays a pivotal role in shaping
                  the landscape of impact investing. Her efforts have left an
                  indelible mark on communities and businesses, underscoring her
                  profound dedication to fostering meaningful change.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:hidden block md:w-[40%] mb-10 md:mb-0flex justify-center">
                <img src={Director3} alt="images" />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Randy Charles Belcher
                </h2>
                <h4 className="uppercase text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  Independent Director
                </h4>
                <p className="text-textGray md:leading-loose">
                  Randy, an Independent Director, led the Disability Impact Fund
                  for four years, showcasing visionary philanthropy. With a
                  notable history as President of the Fossil Foundation and over
                  two decades in commercial leadership at Fossil Inc. across
                  Asia Pacific, Europe, and EMEA, he remains an inspiration.
                </p>
                <p className="text-textGray md:leading-loose pt-3">
                  Armed with an MBA in International Business from the
                  University of Miami Herbert Business School, his journey
                  reflects a commitment to positive change and social impact. As
                  the force behind the Disability Impact Fund, Randy continues
                  to steer a path toward a brighter future, leaving a
                  significant impact on numerous lives.
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] flex justify-center">
                <img src={Director3} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <h2 className="bg-lightGray text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal pt-[50px]">
          Our <span className="text-secondaryColor">Advisor</span>
        </h2>
        <div className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="md:flex w-[80%] mx-auto md:w-full items-center gap-5">
              <div className="md:w-[40%] mb-10 md:mb-0 flex justify-center">
                <img
                  src={Advisor1}
                  alt="images"
                  className="md:w-[445px] md:h-[445px] rounded-xl"
                />
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-primaryColor text-xl md:text-2xl font-medium mb-3">
                  Dr. Biren Sahoo
                </h2>
                <h4 className="uppercase text-secondaryColor text-sm md:text-lg font-medium  mb-1 md:mb-3">
                  Advisor
                </h4>
                <p className="text-textGray md:leading-loose">
                  Dr. Biren Sahoo, a postgraduate in medicine, combines clinical
                  expertise with extensive experience in translational medical
                  research. With a background in top management roles at global
                  organizations including Terumo Corporation, Japan, and Welch
                  Allyn Inc, USA, Dr. Sahoo brings a wealth of knowledge to the
                  field. He is renowned for his lectures on research
                  methodologies worldwide, contributing significantly to
                  advancing medical understanding and practice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default OurTeam;
