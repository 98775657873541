import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { Logout } from "../../../Redux/Action/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import { Divider } from "@mui/material";
import MobileSidebar from "./MobileSidebar";
function DummyRoute() {
  const userData = useSelector((state) => state.authReducer);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { patientData, patientSession, isOpen, index } = userData;
  const [openKeys, setOpenKeys] = useState([]);


  const history = useHistory();
  const dispatch = useDispatch();


  const toggleKey = (key) => {
    setOpenKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  const handleChange = (event) => {
    let dataObj = {
      sessionId: patientSession?.sessionid,
      userCode: patientSession?.code,
    };
    dispatch(Logout(dataObj));

    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
    // window.location.reload();
    
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <div onClick={() => handleNavigation("/myprofile")}>My Profile</div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleNavigation("/manageprofile")}>
          Manage Member Profile
        </div>
      ),
    },
    {
      key: "11",
      label: (
        <div onClick={() => handleNavigation("/medical-summary")}>
          Medical Summary
        </div>
      ),
    },
    {
      key: "3",
      // type: "group",
      label: "My Health Records",
      children: [
        {
          key: "3-1",
          label: (
            <div onClick={() => handleNavigation("/medicalhistory")}>
              Medical History
            </div>
          ),
        },
        {
          key: "3-2",
          label: (
            <div onClick={() => handleNavigation("/myreports")}>My Reports</div>
          ),
        },
        {
          key: "3-3",
          label: (
            <div onClick={() => handleNavigation("/myprescription")}>
              My Prescription
            </div>
          ),
        },
        {
          key: "3-4",
          label: (
            <div onClick={() => handleNavigation("/mylabreports")}>
              My Lab Reports
            </div>
          ),
        },
        {
          key: "3-5",
          label: (
            <div onClick={() => handleNavigation("/myvitals")}>My Vitals</div>
          ),
        },
        {
          key: "3-6",
          label: (
            <div onClick={() => handleNavigation("/myappointments")}>
              My Appointments
            </div>
          ),
        },
      ],
    },
    {
      key: "4",
      label: (
        <div onClick={() => handleNavigation("/myrequests")}>My Requests</div>
      ),
    },
    {
      key: "5",
      label: "My Orders",
      children: [
        {
          key: "5-1",
          label: (
            <div onClick={() => handleNavigation("/laborder")}>Lab Orders</div>
          ),
        },
        {
          key: "5-2",
          label: (
            <div onClick={() => handleNavigation("/medicineorder")}>
              Medicine Orders
            </div>
          ),
        },
        {
          key: "5-3",
          label: (
            <div onClick={() => handleNavigation("/mymembership")}>
              My Membership
            </div>
          ),
        },
      ],
    },
    {
      key: "6",
      label: (
        <div onClick={() => handleNavigation("/mypayment")}>My Payments</div>
      ),
    },
    {
      key: "7",
      label: (
        <div onClick={() => handleNavigation("/mywallet")}>CureBay Wallet</div>
      ),
    },
    {
      key: "8",
      label: (
        <div onClick={() => handleNavigation("/addAddressbook")}>
          My Address Book
        </div>
      ),
    },
    {
      key: "9",
      label: <div onClick={() => handleNavigation("/feedback")}>Feedback</div>,
    },
    {
      key: "12",
      label: (
        <div onClick={() => handleNavigation("/change-password")}>
          Change Password
        </div>
      ),
    },
    {
      key: "13",
      danger: true,
      label: <div onClick={() => handleChange("logout")}>Log Out</div>,
    },
  ];


  const renderItems = (items) => {
    return items.map((item) => (
      <div key={item.key}>
        <ListItem
          button
          onClick={() => {
            if (item.children) toggleKey(item.key);
          }}
          className="flex justify-between items-center text-dark-blue"
        >
          <ListItemText primary = {item.label} className="text-base font-normal text-textGray" />
          {item.children && (
            <IconButton>
              {openKeys.includes(item.key) ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          )}
        </ListItem>
        <Divider/>
        {item.children && (
          <Collapse in={openKeys.includes(item.key)} timeout="auto" unmountOnExit>
            <List className="">
              {renderItems(item.children)}
            </List>
          </Collapse>
        )}
      </div>
    ));
  };

  const handleProfileClick = (e) =>{
    e.preventDefault();
    setIsDrawerOpen(true)
  }

  return (
    // <div className="">
    //    <a
    //       className="cursor-pointer text-White text-xl font-bold bg-lightGreen text-secondaryColor w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex justify-center items-center rounded-full md:hidden"
    //       onClick={handleProfileClick}
    //     >
    //       {patientData && patientData.firstName
    //         ? patientData?.firstName[0].toUpperCase()
    //         : ""}
    //     </a>
    // <div className="hidden md:block" >
    //   <Dropdown
    //     menu={{
    //       items: items,
    //     }}

    //  >
    //          <a
    //       className="cursor-pointer text-White text-xl font-bold bg-lightGreen text-secondaryColor w-[40px] h-[40px] md:w-[50px] md:h-[50px]  justify-center items-center rounded-full hidden md:flex"
    //       onClick={(e) => e.preventDefault()}
    //     >
    //       {patientData && patientData.firstName
    //         ? patientData?.firstName[0].toUpperCase()
    //         : ""}
    //     </a>
    //   </Dropdown>
    // </div>
    // <div className="md:hidden" >
    //   <MobileSidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>
    // {/* <Drawer
    //   anchor="right"
    //   open={isDrawerOpen}
    //   onClose={() => setIsDrawerOpen(!isDrawerOpen)}
    //   classes={{ paper: "w-64" }}
    // >
    //   <div className="p-4 border-b">
    //     <h2 className="text-lg font-bold">  Hi, {patientData && patientData.firstName
    //         ? patientData?.firstName.toUpperCase()
    //         : ""}</h2>
    //   </div>
    //   <List className="p-2 overflow-y-auto">
    //     {renderItems(items)}
    //   </List>
    // </Drawer> */}
    // </div>
    // </div>
    <div className="">
  {/* Profile Icon (Clickable) */}
  <a
    className="cursor-pointer text-white text-xl font-bold bg-lightGreen text-secondaryColor w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex justify-center items-center rounded-full"
    onClick={handleProfileClick}
  >
    {patientData && patientData.firstName
      ? patientData?.firstName[0].toUpperCase()
      : ""}
  </a>

  {/* MobileSidebar - Now Visible for Both Mobile & Desktop */}
  <MobileSidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
</div>

  );
}

export default DummyRoute;
