import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog,Slide, List, ListItem, ListItemText, IconButton, Collapse, Divider, Avatar, Button, ListItemIcon, useMediaQuery, Drawer } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import medicineOrdersIcon from "../../../Assets/Images/sidebar_icons/medicineorders.svg";
import diagnosticIcon from "../../../Assets/Images/sidebar_icons/diagnostic.svg";
import appointmentsIcon from "../../../Assets/Images/sidebar_icons/myappointments.svg";
import membershipIcon from "../../../Assets/Images/sidebar_icons/mymembership.svg";
import medicalHistoryIcon from "../../../Assets/Images/sidebar_icons/medicalhistory.svg";
import vitalsIcon from "../../../Assets/Images/sidebar_icons/myvitals.svg";
import prescriptionsIcon from "../../../Assets/Images/sidebar_icons/myprescription.svg";
import labReportsIcon from "../../../Assets/Images/sidebar_icons/labreport.svg";
import paymentsIcon from "../../../Assets/Images/sidebar_icons/mypayments.svg";
import addressBookIcon from "../../../Assets/Images/sidebar_icons/myaddressbook.svg";
import walletIcon from "../../../Assets/Images/sidebar_icons/curebaywallet.svg";
import changePasswordIcon from "../../../Assets/Images/sidebar_icons/ChangePassword-1.svg";
import needHelpIcon from "../../../Assets/Images/sidebar_icons/needhelp.svg";
import feedbackIcon from "../../../Assets/Images/sidebar_icons/feedback.svg";
import aboutIcon from "../../../Assets/Images/sidebar_icons/aboutus.svg";
import { Logout } from "../../../Redux/Action/userActions";
import manageProfile from "../../../Assets/Images/manage_profile.svg"

function MobileSidebar({isDrawerOpen,setIsDrawerOpen}) {
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
console.log(patientinfoData,"fgvbh");
const userData = useSelector((state) => state.authReducer);
const { patientSession } = userData;
  const [openKeys, setOpenKeys] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleKey = (key) => {
    setOpenKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  const handleNavigation = (path) => {
    history.push(path);
    setIsDrawerOpen(false);
    window.scrollTo(0, 0);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    history.push("/");
  };

  const menuItems = [
    {
      key: "orders",
      label: "My Orders",
      children: [
        { key: "medicine", label: "Medicine Orders", path: "/medicineorder", icon: medicineOrdersIcon },
        { key: "diagnostic", label: "Diagnostic Orders", path: "/laborder", icon: diagnosticIcon },
        { key: "appointments", label: "My Appointments", path: "/myappointments", icon: appointmentsIcon },
        { key: "membership", label: "My Membership", path: "/mymembership", icon: membershipIcon },
      ],
    },
    {
      key: "health_records",
      label: "My Health Records",
      children: [
        { key: "history", label: "Medical History", path: "/medicalhistory", icon: medicalHistoryIcon },
        { key: "vitals", label: "My Vitals", path: "/myvitals", icon: vitalsIcon },
        { key: "prescriptions", label: "My Prescriptions", path: "/myprescription", icon: prescriptionsIcon },
        { key: "lab_reports", label: "My Lab Reports", path: "/mylabreports", icon: labReportsIcon },
      ],
    },
    {
      key: "quick_help",
      label: "Quick Help",
      children: [
        { key: "payments", label: "My Payments", path: "/mypayment", icon: paymentsIcon },
        { key: "address", label: "My Address Book", path: "/addAddressbook", icon: addressBookIcon },
        { key: "wallet", label: "CureBay Wallet", path: "/mywallet", icon: walletIcon },
        { key: "password", label: "Change Password", path: "/change-password", icon: changePasswordIcon },
        // { key: "help", label: "Need Help?", path: "/help", icon: needHelpIcon },
        { key: "feedback", label: "Feedback", path: "/feedback", icon: feedbackIcon },
        { key: "about", label: "About Us", path: "/about-us", icon: aboutIcon },
        { key: "profile", label: "Manage Profile", path: "/manageprofile", icon: manageProfile },
      ],
    },
  ];

    const handleChange = (event) => {
      let dataObj = {
        sessionId: patientSession?.sessionid,
        userCode: patientSession?.code,
      };
      dispatch(Logout(dataObj));
  
      sessionStorage.clear();
      localStorage.clear();
      history.push('/');
      // window.location.reload();
      
    };
    const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div>
      {/* <IconButton onClick={() => setIsDrawerOpen(true)}>
        <Avatar>{patientinfoData?.firstName?.[0]?.toUpperCase() || "U"}</Avatar>
      </IconButton> */}
         <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      PaperProps={{
        style: {
          width: isMobile ? "100%" : "20%", 
          height: "100vh",
        },
      }}
    >
        <div className="p-4 border-b flex items-center justify-between gap-2">
        <div className="flex items-center gap-2  ">
        <Avatar className="bg-lightGreen text-secondaryColor w-10 h-10 flex items-center justify-center text-lg font-semibold">{patientinfoData?.firstName?.[0]?.toUpperCase() || "U"}</Avatar>
      
          <div>
              <h2 className="text-lg font-bold">{patientinfoData?.firstName} {patientinfoData?.lastName || "User"}</h2>
              <p className="text-sm text-gray-500">{patientinfoData?.dob ? new Date().getFullYear() - new Date(patientinfoData.dob).getFullYear() : "-"} years, {patientinfoData?.gender || "-"}</p>
            </div>
          <IconButton onClick={() => history.push('/myprofile')}>
            <EditIcon fontSize="small"  />
          </IconButton>
          </div>
          <IconButton onClick={() => setIsDrawerOpen(false)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <List>
          {menuItems.map((item) => (
            <div key={item.key}>
              <ListItem button onClick={() => toggleKey(item.key)}>
                <ListItemText primary={item.label} />
                {item.children && (openKeys.includes(item.key) ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
              </ListItem>
              <Divider />
              {item.children && (
                <Collapse in={openKeys.includes(item.key)} timeout="auto" unmountOnExit>
                  <List>
                    {item.children.map((subItem) => (
                      <ListItem button key={subItem.key} onClick={() => handleNavigation(subItem.path)}>
                        <ListItemIcon>
                          <img src={subItem.icon} alt={subItem.label} width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary={subItem.label} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
        <div className="p-4">
          <Button fullWidth variant="outlined" startIcon={<LogoutIcon />} onClick={() => handleChange("logout")}
          //  onClick={handleLogout}
           >
            Log Out
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileSidebar;
