import React, { useState } from 'react';
import SmartVitalCheckerHeroIcon from '../../../Assets/Images/smartVitals/smart-vitals-checker-hero-icon.png';
import { Form, Input, Select, Button, Row, Col, Typography } from 'antd';
import Header from "../../Shared/Header";

const { Text } = Typography;
const { Option } = Select;

const FormComponent = ({ onFinish }) => {
  const [form] = Form.useForm();
  const [heightUnit, setHeightUnit] = useState('cm');
  const [weightUnit, setWeightUnit] = useState('kg');
  console.log(form, heightUnit, "ytgyhuhu")

  // const handleFinish = (values) => {
  //   onFinish({ ...values, heightUnit, weightUnit });
  //   console.log(values,heightUnit,weightUnit,"ftgyygy")
  // };

  const handleFinish = (values) => {
    let finalValues = { ...values, heightUnit: "cm", weightUnit };

    if (heightUnit === "ft/inch") {
      const feet = parseInt(values.feet, 10) || 0;
      const inches = parseInt(values.inches, 10) || 0;
      finalValues.heightCm = feet * 30.48 + inches * 2.54;
    }

    console.log(finalValues, "Final form data");
    onFinish(finalValues);
  };



  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="bg-primaryColor text-white py-4 rounded-lg shadow-md w-full mt-24 px-4">
          <p className="font-semibold text-lg ml-3 text-White">
            Create Facial Key:
            <span className="font-normal ml-2 text-base text-White">
              Create a map of your face. You can delete the map at any time.
            </span>
          </p>
        </div>
        <div className="min-h-screen flex flex-col md:flex-row items-center gap-6 px-4 py-6 md:px-12 md:py-8">
          <div className="relative w-full md:w-1/2 lg:w-1/2 mb-6">
            <img
              src={SmartVitalCheckerHeroIcon}
              className="w-full rounded-lg object-cover h-[25rem] sm:h-[30rem] lg:h-[35rem]"
              alt="Smart Vitals Checker"
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white">
              <Text className="text-3xl font-bold md:text-4xl text-White">SmartVitals</Text>
              <Text className="text-lg md:text-xl mt-2 text-White">Monitor Your Health Safely And Easily</Text>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 w-full md:w-1/2 lg:w-1/2">
            <Form
              form={form}
              name="healthForm"
              layout="vertical"
              onFinish={handleFinish}
              requiredMark={false}
            >
              <Row gutter={16}>
              <Col xs={24} className="mb-4">
              <Form.Item
  label={
    <span className="text-lg">
      Height <span className="text-danger">*</span>
    </span>
  }
>
  <Row gutter={8} align="middle" style={{ display: "flex", gap: "8px" }}>
    {/* If CM is selected */}
    {heightUnit === "cm" ? (
      <Col flex="1">
        <Form.Item
          name="heightCm"
          rules={[
            { required: true, message: "Please enter your height" },
            { pattern: /^[0-9]+$/, message: "Only whole numbers are allowed" },
            {
              validator: (_, value) => {
                if (value && (value < 120 || value > 210)) {
                  return Promise.reject("Height must be between 120 cm and 210 cm");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input size="large" type="number" placeholder="Enter height" step="1" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    ) : (
      <>
        {/* Feet Input */}
        <Col flex="1">
          <Form.Item
            name="feet"
            rules={[
              { required: true, message: "Enter feet" },
              { pattern: /^[0-9]+$/, message: "Only whole numbers are allowed" },
              {
                validator: (_, value) => {
                  if (value && (value < 4 || value > 7)) {
                    return Promise.reject("Feet must be between 4 and 7");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input size="large" type="number" placeholder="Feet" step="1" style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        {/* Inches Input */}
        <Col flex="1">
          <Form.Item
            name="inches"
            rules={[
              { required: true, message: "Enter inches" },
              { pattern: /^[0-9]+$/, message: "Only whole numbers are allowed" },
              {
                validator: (_, value) => {
                  if (value && (value < 0 || value >= 12)) {
                    return Promise.reject("Inches must be between 0 and 11");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input size="large" type="number" placeholder="Inches" step="1" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </>
    )}

    {/* Unit Selection */}
    <Col flex="1">
      <Select
        size="large"
        value={heightUnit}
        onChange={setHeightUnit}
        style={{ width: "100%", marginBottom: "25px" }}
      >
        <Select.Option value="cm">cm</Select.Option>
        <Select.Option value="ft/inch">ft/inch</Select.Option>
      </Select>
    </Col>
  </Row>

  {/* Validation Message */}
  <Row>
    <Col span={24}>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const feet = getFieldValue("feet");
          const inches = getFieldValue("inches");

          // Check if values are entered before calculating height
          if (feet !== undefined && inches !== undefined && feet !== "" && inches !== "") {
            const heightInCm = feet * 30.48 + inches * 2.54;
            if (heightInCm < 120 || heightInCm > 210) {
              return (
                <div className="text-danger">
                  Height must be between 120 cm and 210 cm (current: {Math.round(heightInCm)} cm)
                </div>
              );
            }
          }
          return null;
        }}
      </Form.Item>
    </Col>
  </Row>
</Form.Item>


</Col>


                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Weight <span className="text-danger">*</span></span>}
                    name="weight"
                    rules={[
                      { required: true, message: 'Please enter your weight' },
                      { pattern: /^[0-9]+$/, message: 'Only whole numbers are allowed' },
                      {
                        validator: (_, value) => {
                          if (value && (value < 20 || value > 1000)) {
                            return Promise.reject('Weight must be between 20 kg and 1000 kg');
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      placeholder="Enter weight"
                      addonAfter={
                        <Select defaultValue="kg" onChange={value => setWeightUnit(value)}>
                          <Option value="kg">kg</Option>
                          <Option value="lbs">lbs</Option>
                        </Select>
                      }
                      step="1"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Diabetes <span className="text-danger">*</span></span>}
                    name="diabetes"
                    rules={[{ required: true, message: 'Please select an option' }]}
                  >
                    <Select size="large" placeholder="Select">
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Smoke <span className="text-danger">*</span></span>}
                    name="smoke"
                    rules={[{ required: true, message: 'Please select an option' }]}
                  >
                    <Select size="large" placeholder="Select">
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full bg-blue-900 hover:bg-blue-800 text-white text-lg">
                  Next
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
